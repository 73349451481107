// src/pages/CrmPage.js
import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { 
  Users, BarChart2, MessageSquare, Briefcase, 
  Tag, FileText, PlusCircle, ChevronRight
} from 'lucide-react';

const CrmPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Define tabs for CRM navigation
  const tabs = [
    { id: 'customers', name: 'Customers', icon: Users, path: '/crm/customers' },
    { id: 'leads', name: 'Leads & Opportunities', icon: Briefcase, path: '/crm/leads' },
    { id: 'sales', name: 'Sales Pipeline', icon: BarChart2, path: '/crm/sales' },
    { id: 'communicate', name: 'Communicate', icon: MessageSquare, path: '/crm/communicate' },
    { id: 'support', name: 'Support & Tickets', icon: FileText, path: '/crm/support' },
    { id: 'reports', name: 'Reports', icon: Tag, path: '/crm/reports' }
  ];
  
  // Get active tab from current location
  const getActiveTab = () => {
    const path = location.pathname;
    const activeTab = tabs.find(tab => path.startsWith(tab.path));
    return activeTab ? activeTab.id : 'customers'; // Default to customers
  };
  
  const activeTab = getActiveTab();

  return (
    <div className="space-y-6">
     
          <div className="flex justify-between items-center">
        
              <p className="text-purple-800 text-sm ml-2">
                Manage customers, leads, sales and support in one place
              </p>
        
            
            <button
              onClick={() => {
                // Navigate to appropriate "new" page based on active tab
                switch(activeTab) {
                  case 'customers':
                    navigate('/crm/customers/new');
                    break;
                  case 'leads':
                    navigate('/crm/leads/new');
                    break;
                  case 'sales':
                    navigate('/crm/sales/new');
                    break;
                  case 'support':
                    navigate('/crm/support/new');
                    break;
                  default:
                    navigate('/crm/customers/new');
                }
              }}
              className="px-4 py-2 bg-white text-[#190192] rounded-lg hover:bg-opacity-90 font-medium transition-all flex items-center"
            >
              <PlusCircle size={18} className="mr-2" />
              {activeTab === 'customers' ? 'Add Customer' : 
               activeTab === 'leads' ? 'Add Lead' :
               activeTab === 'sales' ? 'Add Opportunity' :
               activeTab === 'support' ? 'Add Ticket' : 'Add New'}
            </button>
          
        </div>
        
        {/* Tabs Navigation */}
        <div className="px-6 border-b border-gray-200">
          <nav className="flex space-x-6 overflow-x-auto hide-scrollbar">
            {tabs.map(tab => (
              <button
                key={tab.id}
                onClick={() => navigate(tab.path)}
                className={`flex items-center py-4 px-1 border-b-2 font-medium text-sm whitespace-nowrap ${
                  activeTab === tab.id
                    ? 'border-[#190192] text-[#190192]'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
              >
                <tab.icon size={18} className="mr-2" />
                {tab.name}
              </button>
            ))}
          </nav>
        
      </div>

      {/* Content Area */}
      <Outlet />
    </div>
  );
};

export default CrmPage;