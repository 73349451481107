// src/components/crm/communication/InteractionDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, User, 
  Mail, Phone, Calendar, Tag, MessageSquare, ChevronRight,
  Clock, Link as LinkIcon, ArrowRight, CheckCircle,
  ExternalLink, Building, Save, X, PlusCircle
} from 'lucide-react';

const InteractionDetail = () => {
  const { interactionId } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [interaction, setInteraction] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const [noteText, setNoteText] = useState('');
  
  // Fetch interaction details
  useEffect(() => {
    const fetchInteractionDetails = async () => {
      setLoading(true);
      try {
        // In a real app, this would be an API call
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock interaction data
        const mockInteraction = {
          id: interactionId,
          type: 'email',
          direction: 'inbound',
          status: 'completed',
          date: '2025-03-20T14:30:00Z',
          duration: 'email', // for calls this would be the duration in seconds
          subject: 'Product Information Request',
          summary: 'Customer inquired about new product features and pricing options.',
          content: `Hello,

I'm interested in learning more about your software solutions for task management. Could you please send me information about your pricing plans and key features?

Also, do you offer any integrations with calendar applications?

Thank you,
John Smith`,
          contact: {
            id: '123',
            name: 'John Smith',
            email: 'john@example.com',
            phone: '+1 (555) 123-4567',
            company: 'Acme Corporation'
          },
          assignedTo: 'Sarah Kim',
          relatedTo: {
            type: 'company',
            id: '456',
            name: 'Acme Corporation'
          },
          tags: ['Information Request', 'Follow Up', 'New Lead'],
          attachments: [
            { id: '1', name: 'Product_Brochure.pdf', type: 'application/pdf', size: '1.2MB' }
          ],
          nextSteps: 'Send pricing information and schedule a demo call.',
          followUpDate: '2025-03-25T10:00:00Z',
          notes: [
            { id: '1', text: 'Customer mentioned they need a solution by end of Q2.', author: 'Sarah Kim', date: '2025-03-20T15:45:00Z' },
            { id: '2', text: 'Already researching competitive solutions - need to emphasize our unique features.', author: 'Michael Johnson', date: '2025-03-21T09:30:00Z' }
          ],
          isProcessed: true,
          source: 'Gmail Integration',
          createdAt: '2025-03-20T14:35:00Z',
          updatedAt: '2025-03-21T09:30:00Z'
        };
        
        setInteraction(mockInteraction);
        setNoteText(''); // Clear note text field
      } catch (error) {
        console.error('Error fetching interaction details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInteractionDetails();
  }, [interactionId]);

  // Format date
  const formatDate = (dateString, includeTime = true) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    return includeTime 
      ? new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }).format(date)
      : new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        }).format(date);
  };

  // Get interaction type icon
  const getInteractionIcon = (type) => {
    switch (type) {
      case 'email':
        return <Mail className="text-blue-500" />;
      case 'call':
        return <Phone className="text-green-500" />;
      case 'meeting':
        return <Calendar className="text-purple-500" />;
      case 'message':
        return <MessageSquare className="text-orange-500" />;
      default:
        return <MessageSquare className="text-gray-500" />;
    }
  };

  // Handle delete
  const handleDelete = async () => {
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Navigate back to the interactions list
      navigate('/crm/interactions');
    } catch (error) {
      console.error('Error deleting interaction:', error);
    }
  };

  // Handle add note
  const handleAddNote = () => {
    if (!noteText.trim()) return;
    
    const newNote = {
      id: Date.now().toString(),
      text: noteText,
      author: 'Sarah Kim', // This would be the current user
      date: new Date().toISOString()
    };
    
    setInteraction(prev => ({
      ...prev,
      notes: [...prev.notes, newNote],
      updatedAt: new Date().toISOString()
    }));
    
    setNoteText('');
    setEditingNote(false);
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!interaction) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Interaction not found</h3>
          <p className="text-gray-500 mb-6">The interaction you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/crm/interactions')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Interactions
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/crm/interactions"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Interactions
          </Link>
          
          <div className="flex space-x-3">
            <Link
              to={`/crm/interactions/edit/${interactionId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Interaction Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-start gap-6">
            <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
              {getInteractionIcon(interaction.type)}
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{interaction.subject}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  interaction.direction === 'inbound' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                }`}>
                  {interaction.direction === 'inbound' ? 'Inbound' : 'Outbound'}
                </span>
              </div>
              
              <div className="flex items-center text-sm text-gray-500 mt-2">
                <Clock size={16} className="mr-1.5" />
                <span>{formatDate(interaction.date)}</span>
                {interaction.type === 'call' && interaction.duration && (
                  <span className="ml-3">Duration: {Math.floor(interaction.duration / 60)}:{(interaction.duration % 60).toString().padStart(2, '0')}</span>
                )}
              </div>
              
              {interaction.source && (
                <div className="text-sm text-gray-500 mt-1">
                  <span>Source: {interaction.source}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Main Content */}
        <div className="p-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Left Column: Interaction Details */}
            <div className="lg:col-span-2 space-y-8">
              {/* Summary */}
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-2">Summary</h3>
                <p className="text-gray-700">{interaction.summary}</p>
              </div>
              
              {/* Content */}
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Content</h3>
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <pre className="text-gray-700 whitespace-pre-wrap font-sans">{interaction.content}</pre>
                </div>
              </div>
              
              {/* Attachments */}
              {interaction.attachments && interaction.attachments.length > 0 && (
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Attachments</h3>
                  <div className="flex flex-wrap gap-3">
                    {interaction.attachments.map((attachment) => (
                      <div 
                        key={attachment.id} 
                        className="flex items-center p-3 bg-gray-50 border border-gray-200 rounded-lg"
                      >
                        <div className="mr-3">
                          <Document size={24} className="text-gray-400" />
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="text-sm font-medium text-gray-900 truncate">{attachment.name}</p>
                          <p className="text-xs text-gray-500">{attachment.size}</p>
                        </div>
                        <button className="ml-2 p-1.5 text-gray-500 hover:text-[#190192] rounded">
                          <Download size={16} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              {/* Next Steps */}
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">Next Steps</h3>
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <p className="text-gray-700">{interaction.nextSteps || 'No next steps defined.'}</p>
                  
                  {interaction.followUpDate && (
                    <div className="mt-3 pt-3 border-t border-gray-200 flex items-center text-sm">
                      <Calendar size={16} className="mr-1.5 text-gray-500" />
                      <span className="text-gray-700">Follow-up scheduled for: {formatDate(interaction.followUpDate)}</span>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Notes */}
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium text-gray-900">Notes</h3>
                  {!editingNote && (
                    <button 
                      onClick={() => setEditingNote(true)}
                      className="text-[#190192] hover:text-[#2C0DB5] text-sm flex items-center"
                    >
                      <PlusCircle size={14} className="mr-1" />
                      Add Note
                    </button>
                  )}
                </div>
                
                {editingNote && (
                  <div className="mb-4">
                    <textarea
                      value={noteText}
                      onChange={(e) => setNoteText(e.target.value)}
                      placeholder="Enter your note here..."
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-[#190192] focus:border-[#190192]"
                      rows="3"
                    ></textarea>
                    <div className="flex justify-end mt-2 space-x-2">
                      <button 
                        onClick={() => setEditingNote(false)}
                        className="px-3 py-1.5 border border-gray-300 text-gray-700 rounded hover:bg-gray-50"
                      >
                        Cancel
                      </button>
                      <button 
                        onClick={handleAddNote}
                        className="px-3 py-1.5 bg-[#190192] text-white rounded hover:bg-[#2C0DB5] flex items-center"
                        disabled={!noteText.trim()}
                      >
                        <Save size={14} className="mr-1.5" />
                        Save Note
                      </button>
                    </div>
                  </div>
                )}
                <div className="space-y-4">
                  {interaction.notes && interaction.notes.length > 0 ? (
                    interaction.notes.map((note) => (
                      <div key={note.id} className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                        <div className="flex justify-between mb-2">
                          <span className="font-medium text-gray-900">{note.author}</span>
                          <span className="text-xs text-gray-500">{formatDate(note.date)}</span>
                        </div>
                        <p className="text-gray-700">{note.text}</p>
                      </div>
                    ))
                  ) : (
                    <div className="text-center py-6 bg-gray-50 rounded-lg border border-gray-200">
                      <p className="text-gray-500">No notes yet. Add a note to track important information about this interaction.</p>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Tags */}
              {interaction.tags && interaction.tags.length > 0 && (
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Tags</h3>
                  <div className="flex flex-wrap gap-2">
                    {interaction.tags.map((tag, index) => (
                      <span key={index} className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm">
                        {tag}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
            
            {/* Right Column: Related Information */}
            <div className="lg:col-span-1">
              {/* Contact Information */}
              <div className="bg-gray-50 rounded-xl p-5 mb-6">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Contact Information</h3>
                
                <div className="space-y-4">
                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0 mr-3">
                      <User size={18} className="text-blue-600" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">{interaction.contact.name}</p>
                      <Link to={`/crm/customers/${interaction.contact.id}`} className="text-xs text-[#190192] hover:underline flex items-center mt-1">
                        View Contact <ChevronRight size={12} className="ml-1" />
                      </Link>
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    {interaction.contact.email && (
                      <div className="flex items-center text-sm">
                        <Mail size={14} className="text-gray-400 mr-2" />
                        <a href={`mailto:${interaction.contact.email}`} className="text-gray-600 hover:text-[#190192]">
                          {interaction.contact.email}
                        </a>
                      </div>
                    )}
                    
                    {interaction.contact.phone && (
                      <div className="flex items-center text-sm">
                        <Phone size={14} className="text-gray-400 mr-2" />
                        <a href={`tel:${interaction.contact.phone}`} className="text-gray-600 hover:text-[#190192]">
                          {interaction.contact.phone}
                        </a>
                      </div>
                    )}
                    
                    {interaction.contact.company && (
                      <div className="flex items-center text-sm">
                        <Building size={14} className="text-gray-400 mr-2" />
                        <span className="text-gray-600">{interaction.contact.company}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              {/* Related To */}
              {interaction.relatedTo && (
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Related To</h3>
                  
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0 mr-3">
                      <Building size={18} className="text-purple-600" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-900">{interaction.relatedTo.name}</p>
                      <p className="text-xs text-gray-500 capitalize">{interaction.relatedTo.type}</p>
                    </div>
                  </div>
                  
                  <Link 
                    to={`/crm/${interaction.relatedTo.type === 'company' ? 'customers' : interaction.relatedTo.type}/${interaction.relatedTo.id}`}
                    className="mt-3 text-sm text-[#190192] hover:text-[#2C0DB5] flex items-center"
                  >
                    View {interaction.relatedTo.type === 'company' ? 'Company' : interaction.relatedTo.type}
                    <ArrowRight size={14} className="ml-1" />
                  </Link>
                </div>
              )}
              
              {/* Assigned To */}
              {interaction.assignedTo && (
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Assigned To</h3>
                  
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0 mr-3">
                      <User size={18} className="text-green-600" />
                    </div>
                    <p className="text-sm font-medium text-gray-900">{interaction.assignedTo}</p>
                  </div>
                </div>
              )}
              
              {/* Quick Actions */}
              <div className="bg-gray-50 rounded-xl p-5">
                <h3 className="text-lg font-medium text-gray-900 mb-3">Quick Actions</h3>
                
                <div className="space-y-2">
                  <Link
                    to={`/crm/interactions/new?contactId=${interaction.contact.id}&type=email`}
                    className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                  >
                    <Mail size={16} className="mr-2" />
                    Send Follow-Up Email
                  </Link>
                  
                  <Link
                    to={`/crm/interactions/new?contactId=${interaction.contact.id}&type=call`}
                    className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                  >
                    <Phone size={16} className="mr-2" />
                    Log Call
                  </Link>
                  
                  <Link
                    to={`/crm/tasks/new?contactId=${interaction.contact.id}`}
                    className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                  >
                    <CheckCircle size={16} className="mr-2" />
                    Create Task
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Interaction</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this interaction? This action cannot be undone and all related notes will be permanently removed.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Document icon for attachments
function Document({ size, className }) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={size} 
      height={size} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className={className}
    >
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
      <polyline points="14 2 14 8 20 8"></polyline>
      <line x1="16" y1="13" x2="8" y2="13"></line>
      <line x1="16" y1="17" x2="8" y2="17"></line>
      <polyline points="10 9 9 9 8 9"></polyline>
    </svg>
  );
}

export default InteractionDetail;