// src/components/human-capital/team/TeamMemberForm.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ChevronLeft, Save, AlertCircle, User,
  Mail, Phone, Calendar, MapPin, Briefcase,
  FileText, Image, Upload, X
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { doc, getDoc, setDoc, updateDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../firebase/config';

const TeamMemberForm = ({ isEditing = false }) => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userProfile } = useAuth();
  const { activeBusiness } = useContext(AppContext);
  
  const [loading, setLoading] = useState(isEditing);
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [useCurrentUserProfile, setUseCurrentUserProfile] = useState(false);

  const businessId = activeBusiness?.id || currentUser?.businessId;
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    position: '',
    department: '',
    email: '',
    phone: '',
    address: '',
    startDate: '',
    bio: '',
    skills: [],
    avatar: null,
    avatarUrl: '',
    status: 'active'
  });
  
  // For skills input
  const [skillInput, setSkillInput] = useState('');
  
  // Department options
  const departments = [
    'Engineering',
    'Marketing',
    'Sales',
    'Customer Success',
    'Finance',
    'Human Resources',
    'Product',
    'Design',
    'Operations',
    'Research & Development',
    'Legal',
    'Executive',
    'Other'
  ];
  
  // Status options
  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'on-leave', label: 'On Leave' },
    { value: 'contractor', label: 'Contractor' },
    { value: 'terminated', label: 'Terminated' }
  ];

  // Fetch team member data if editing
  useEffect(() => {
    const fetchMemberData = async () => {
      if (!isEditing || !memberId || !currentUser) return;
      
      setLoading(true);
      
      try {
        const memberRef = doc(db, 'team_members', memberId);
        const memberDoc = await getDoc(memberRef);
        
        if (memberDoc.exists()) {
          const memberData = memberDoc.data();
          
          // Format date for form input
          const formatFirestoreDate = (timestamp) => {
            if (!timestamp) return '';
            const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
            return date.toISOString().split('T')[0];
          };
          
          setFormData({
            firstName: memberData.firstName || '',
            lastName: memberData.lastName || '',
            position: memberData.position || '',
            department: memberData.department || '',
            email: memberData.email || '',
            phone: memberData.phone || '',
            address: memberData.address || '',
            startDate: formatFirestoreDate(memberData.startDate),
            bio: memberData.bio || '',
            skills: memberData.skills || [],
            avatarUrl: memberData.avatarUrl || '',
            status: memberData.status || 'active'
          });
        }
      } catch (error) {
        console.error('Error fetching team member data:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchMemberData();
  }, [isEditing, memberId, currentUser]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkboxes
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle file upload for avatar
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setFormErrors({ avatar: "Image is too large. Maximum size is 5MB." });
      return;
    }
    
    // Check file type (only images)
    if (!file.type.match('image.*')) {
      setFormErrors({ avatar: "Only image files are allowed." });
      return;
    }
    
    // Create preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData(prev => ({
        ...prev,
        avatar: file,
        avatarUrl: reader.result
      }));
    };
    reader.readAsDataURL(file);
  };

  // Handle skill input
  const handleSkillKeyDown = (e) => {
    if (e.key === 'Enter' && skillInput.trim()) {
      e.preventDefault();
      
      // Add skill if it doesn't already exist
      if (!formData.skills.includes(skillInput.trim())) {
        setFormData(prev => ({
          ...prev,
          skills: [...prev.skills, skillInput.trim()]
        }));
      }
      
      setSkillInput('');
    }
  };
  
  // Remove skill
  const handleRemoveSkill = (skillToRemove) => {
    setFormData(prev => ({
      ...prev,
      skills: prev.skills.filter(skill => skill !== skillToRemove)
    }));
  };
  
  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required';
    }
    
    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }
    
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    
    if (!formData.position.trim()) {
      errors.position = 'Position is required';
    }
    
    if (!formData.department) {
      errors.department = 'Department is required';
    }
    
    return errors;
  };

  // Use current user profile data
  const useCurrentProfile = () => {
    if (!userProfile) return;
    
    setFormData(prev => ({
      ...prev,
      firstName: userProfile.firstName || '',
      lastName: userProfile.lastName || '',
      email: currentUser?.email || '',
      mobile: currentUser?.mobile || '',
      address: currentUser?.address || '',
      profileImageUrl: currentUser?.profileImageUrl || ''
    }));
    
    setUseCurrentUserProfile(true);
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setSaving(true);
    
    try {
      // Handle avatar upload if a new file was selected
      let avatarUrl = formData.avatarUrl;
      
      if (formData.avatar && formData.avatar instanceof File) {
        // Create a storage reference
        const storageRef = ref(storage, `team_members/${currentUser.uid}_${Date.now()}_${formData.avatar.name}`);
        
        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, formData.avatar);
        
        // Wait for the upload to complete
        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Track upload progress if needed
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload progress: ${progress}%`);
            },
            (error) => {
              reject(error);
            },
            async () => {
              try {
                // Get the download URL
                avatarUrl = await getDownloadURL(uploadTask.snapshot.ref);
                resolve();
              } catch (err) {
                reject(err);
              }
            }
          );
        });
      }
      
      // Prepare team member data
      const memberData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        position: formData.position,
        department: formData.department,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        startDate: formData.startDate ? new Date(formData.startDate) : null,
        bio: formData.bio,
        skills: formData.skills,
        avatarUrl: avatarUrl,
        status: formData.status,
        updatedAt: serverTimestamp(),
        businessId: businessId,
      };
      
      if (isEditing) {
        // Update existing team member
        await updateDoc(doc(db, 'team_members', memberId), memberData);
        navigate(`/human-capital/team/${memberId}`);
      } else {
        // Add new team member
        const memberRef = await addDoc(collection(db, 'team_members'), {
          ...memberData,
          createdAt: serverTimestamp(),
          createdBy: currentUser.uid
        });
        
        navigate(`/human-capital/team/${memberRef.id}`);
      }
    } catch (error) {
      console.error('Error saving team member:', error);
      setFormErrors({ submit: 'Failed to save team member. Please try again.' });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center">
          <button 
            type="button"
            onClick={() => navigate('/human-capital/team')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center mr-4"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit Team Member' : 'Add New Team Member'}
          </h2>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        {/* Form Error Message */}
        {formErrors.submit && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
            <div className="flex">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{formErrors.submit}</p>
            </div>
          </div>
        )}
        
        {/* Option to use current user profile */}
        {!isEditing && !useCurrentUserProfile && userProfile && (
          <div className="mb-6 bg-blue-50 border-l-4 border-blue-500 p-4 text-blue-700">
            <div className="flex justify-between items-center">
              <div className="flex">
                <User className="h-5 w-5 mr-2 flex-shrink-0" />
                <p>Want to add yourself as a team member? Fill form with your profile data.</p>
              </div>
              <button
                type="button"
                onClick={useCurrentProfile}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
              >
                Use My Profile
              </button>
            </div>
          </div>
        )}
        
        {/* Basic Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Personal Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          {/* First Name */}
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              className={`block w-full rounded-lg border ${
                formErrors.firstName ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.firstName}
              onChange={handleInputChange}
            />
            {formErrors.firstName && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.firstName}
              </p>
            )}
          </div>
          
          {/* Last Name */}
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
              Last Name <span className="text-red-500">*</span>
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              className={`block w-full rounded-lg border ${
                formErrors.lastName ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.lastName}
              onChange={handleInputChange}
            />
            {formErrors.lastName && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.lastName}
              </p>
            )}
          </div>
          
          {/* Avatar Upload */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Profile Picture
            </label>
            <div className="flex items-center">
              {formData.avatarUrl ? (
                <div className="relative">
                  <img 
                    src={formData.avatarUrl} 
                    alt="Avatar preview" 
                    className="h-16 w-16 rounded-full object-cover border border-gray-200"
                  />
                  <button
                    type="button"
                    onClick={() => setFormData({ ...formData, avatar: null, avatarUrl: '' })}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  >
                    <X size={12} />
                  </button>
                </div>
              ) : (
                <label className="h-16 w-16 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer border border-dashed border-gray-300 hover:bg-gray-50">
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  <Upload size={20} className="text-gray-400" />
                </label>
              )}
              <div className="ml-4">
                <label htmlFor="avatar-upload" className="cursor-pointer text-sm text-[#190192] hover:text-[#2C0DB5]">
                  {formData.avatarUrl ? 'Change Photo' : 'Upload Photo'}
                  <input
                    id="avatar-upload"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                </label>
                <p className="text-xs text-gray-500 mt-1">JPEG, PNG or GIF (Max 5MB)</p>
                {formErrors.avatar && (
                  <p className="text-red-600 text-xs flex items-center mt-1">
                    <AlertCircle size={12} className="mr-1" /> {formErrors.avatar}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        
        {/* Professional Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4 mt-8">Professional Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Position */}
          <div>
            <label htmlFor="position" className="block text-sm font-medium text-gray-700 mb-1">
              Position <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Briefcase size={18} className="text-gray-400" />
              </div>
              <input
                id="position"
                name="position"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.position ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.position}
                onChange={handleInputChange}
                placeholder="e.g., Software Engineer"
              />
            </div>
            {formErrors.position && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.position}
              </p>
            )}
          </div>
          
          {/* Department */}
          <div>
            <label htmlFor="department" className="block text-sm font-medium text-gray-700 mb-1">
              Department <span className="text-red-500">*</span>
            </label>
            <select
              id="department"
              name="department"
              className={`block w-full rounded-lg border ${
                formErrors.department ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.department}
              onChange={handleInputChange}
            >
              <option value="">Select Department</option>
              {departments.map(department => (
                <option key={department} value={department}>{department}</option>
              ))}
            </select>
            {formErrors.department && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.department}
              </p>
            )}
          </div>
          
          {/* Start Date */}
          <div>
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar size={18} className="text-gray-400" />
              </div>
              <input
                id="startDate"
                name="startDate"
                type="date"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.startDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          {/* Status */}
          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              id="status"
              name="status"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.status}
              onChange={handleInputChange}
            >
              {statusOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </div>
        
        {/* Contact Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4 mt-8">Contact Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={18} className="text-gray-400" />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.email ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.email && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.email}
              </p>
            )}
          </div>
          
          {/* Phone */}
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone size={18} className="text-gray-400" />
              </div>
              <input
                id="phone"
                name="phone"
                type="tel"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          {/* Address */}
          <div className="md:col-span-2">
            <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
              Address
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin size={18} className="text-gray-400" />
              </div>
              <input
                id="address"
                name="address"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Street address, city, state, zip"
              />
            </div>
          </div>
        </div>
        
        {/* Additional Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4 mt-8">Additional Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Bio */}
          <div className="md:col-span-2">
            <label htmlFor="bio" className="block text-sm font-medium text-gray-700 mb-1">
              Bio
            </label>
            <div className="relative">
              <div className="absolute top-3 left-3 flex items-start pointer-events-none">
                <FileText size={18} className="text-gray-400" />
              </div>
              <textarea
                id="bio"
                name="bio"
                rows="4"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.bio}
                onChange={handleInputChange}
                placeholder="Brief professional background and expertise"
              ></textarea>
            </div>
          </div>
          
          {/* Skills */}
          <div className="md:col-span-2">
            <label htmlFor="skills" className="block text-sm font-medium text-gray-700 mb-1">
              Skills
            </label>
            <div className="relative">
              <input
                id="skills"
                type="text"
                className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={skillInput}
                onChange={(e) => setSkillInput(e.target.value)}
                onKeyDown={handleSkillKeyDown}
                placeholder="Type a skill and press Enter"
              />
            </div>
            
            {formData.skills.length > 0 && (
              <div className="mt-2 flex flex-wrap gap-2">
                {formData.skills.map((skill, index) => (
                  <div key={index} className="bg-gray-100 rounded-full px-3 py-1 text-sm flex items-center">
                    {skill}
                    <button
                      type="button"
                      onClick={() => handleRemoveSkill(skill)}
                      className="ml-1.5 text-gray-500 hover:text-red-500"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            )}
            <p className="mt-1 text-xs text-gray-500">
              Press Enter after each skill to add it to the list
            </p>
          </div>
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/human-capital/team')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={saving}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={saving}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {saving ? (
              <>
                <div className="animate-spin -ml-1 mr-2 h-4 w-4 text-white rounded-full border-2 border-white border-t-transparent"></div>
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                {isEditing ? 'Update Team Member' : 'Add Team Member'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TeamMemberForm;