// src/components/human-capital/team/TeamList.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, Users, 
  UserCircle, Phone, Mail, Briefcase, X, AlertCircle
} from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useAuth } from '../../../context/AuthContext';
import { AppContext } from '../../../context/AppContext';

const TeamList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDepartment, setFilterDepartment] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState(null);
  
  const { currentUser } = useAuth();
  const { activeBusiness } = useContext(AppContext);

  // Fetch team members
  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (!currentUser || !activeBusiness?.id) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        // Query Firestore for team members in the active business
        const teamQuery = query(
          collection(db, 'team_members'),
          where('businessId', '==', activeBusiness.id)
        );
        
        const querySnapshot = await getDocs(teamQuery);
        const members = [];
        
        querySnapshot.forEach((doc) => {
          members.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setTeamMembers(members);
      } catch (err) {
        console.error('Error fetching team members:', err);
        setError('Failed to load team members. Please try again later.');
        
        // If in development or no real data yet, use mock data
        if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_USE_MOCK_DATA === 'true') {
          setTeamMembers([
            {
              id: '1',
              firstName: 'John',
              lastName: 'Doe',
              position: 'CEO',
              department: 'Executive',
              email: 'john@example.com',
              phone: '+1 (555) 123-4567',
              status: 'active',
              photoUrl: null
            },
            {
              id: '2',
              firstName: 'Jane',
              lastName: 'Smith',
              position: 'Marketing Director',
              department: 'Marketing',
              email: 'jane@example.com',
              phone: '+1 (555) 987-6543',
              status: 'active',
              photoUrl: null
            },
            {
              id: '3',
              firstName: 'Robert',
              lastName: 'Johnson',
              position: 'Senior Developer',
              department: 'Engineering',
              email: 'robert@example.com',
              phone: '+1 (555) 234-5678',
              status: 'active',
              photoUrl: null
            },
            {
              id: '4',
              firstName: 'Emily',
              lastName: 'Davis',
              position: 'HR Manager',
              department: 'Human Resources',
              email: 'emily@example.com',
              phone: '+1 (555) 345-6789',
              status: 'active',
              photoUrl: null
            },
            {
              id: '5',
              firstName: 'Michael',
              lastName: 'Wilson',
              position: 'Financial Analyst',
              department: 'Finance',
              email: 'michael@example.com',
              phone: '+1 (555) 456-7890',
              status: 'inactive',
              photoUrl: null
            }
          ]);
        }
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchTeamMembers();
  }, [currentUser, activeBusiness?.id]);

  // Get unique departments for filter
  const departments = ['all', ...new Set(teamMembers.map(member => member.department).filter(Boolean))];

  // Filter team members based on search and department
  const getFilteredTeamMembers = () => {
    let filtered = teamMembers;
    
    // Filter by search term
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(member => 
        `${member.firstName} ${member.lastName}`.toLowerCase().includes(term) ||
        member.email?.toLowerCase().includes(term) ||
        member.position?.toLowerCase().includes(term) ||
        member.department?.toLowerCase().includes(term)
      );
    }
    
    // Filter by department
    if (filterDepartment !== 'all') {
      filtered = filtered.filter(member => member.department === filterDepartment);
    }
    
    return filtered;
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search by name, position, or department..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterDepartment}
            onChange={(e) => setFilterDepartment(e.target.value)}
          >
            <option value="all">All Departments</option>
            {departments.filter(d => d !== 'all').map(department => (
              <option key={department} value={department}>{department}</option>
            ))}
          </select>
          
          <Link
            to="/human-capital/team/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Add Team Member
          </Link>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 rounded-lg py-6 px-6 text-center">
          <AlertCircle size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Error Loading Team</h3>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
          >
            Try Again
          </button>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {teamMembers.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Users size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No team members found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't added any team members yet. Click the 'Add Team Member' button to get started.
              </p>
              <Link
                to="/human-capital/team/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Add Your First Team Member
              </Link>
            </div>
          ) : getFilteredTeamMembers().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Users size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching team members</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any team members matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterDepartment('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Team Members Table */
            <div className="bg-white rounded-xl shadow-md overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Position
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Department
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Contact
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {getFilteredTeamMembers().map((member) => (
                      <tr key={member.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center text-purple-800">
                              {member.photoUrl ? (
                                <img src={member.photoUrl} alt="" className="h-10 w-10 rounded-full" />
                              ) : (
                                <UserCircle size={20} />
                              )}
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{member.firstName} {member.lastName}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{member.position}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{member.department}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 flex items-center">
                            <Mail size={14} className="mr-1 text-gray-400" /> {member.email}
                          </div>
                          {member.phone && (
                            <div className="text-sm text-gray-500 flex items-center">
                              <Phone size={14} className="mr-1 text-gray-400" /> {member.phone}
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            member.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                          }`}>
                            {member.status === 'active' ? 'Active' : 'Inactive'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/human-capital/team/${member.id}`}
                            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                          >
                            View <ChevronRight size={16} className="ml-1" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TeamList;