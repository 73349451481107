// src/components/crm/support/TicketDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, User, 
  Mail, Phone, Calendar, Tag, AlertCircle, 
  MessageSquare, Clock, MessageCircle, X,
  Send, PaperclipIcon, Plus, Image, Paperclip,
  Check, Building, ArrowUpRight
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { 
  doc, getDoc, updateDoc, deleteDoc, arrayUnion, serverTimestamp,
  onSnapshot
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../firebase/config';

const TicketDetail = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newReply, setNewReply] = useState('');
  const [replyAttachments, setReplyAttachments] = useState([]);
  const [sending, setSending] = useState(false);
  const [isPrivateReply, setIsPrivateReply] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [error, setError] = useState(null);
  
  // Mock ticket data for demo purposes
  const mockTicket = {
    id: ticketId,
    subject: 'Cannot access account after password reset',
    customer: {
      name: 'John Smith',
      email: 'john@example.com',
      phone: '+1 (555) 123-4567',
      company: 'Acme Corporation'
    },
    status: 'in_progress',
    priority: 'high',
    category: 'Account Access',
    assignedTo: 'Sarah Kim',
    createdAt: '2025-03-20T10:15:00Z',
    lastUpdated: '2025-03-21T14:30:00Z',
    dueDate: '2025-03-23T17:00:00Z',
    conversation: {
      messages: [
        {
          id: '1',
          from: 'customer',
          sender: 'John Smith',
          content: 'I recently reset my password but now I cannot log in to my account. The system says my credentials are invalid. I\'ve tried resetting the password again but I\'m still having the same issue.',
          timestamp: '2025-03-20T10:15:00Z',
          attachments: []
        },
        {
          id: '2',
          from: 'agent',
          sender: 'Sarah Kim',
          content: 'Hi John, I\'m sorry to hear you\'re having trouble accessing your account. Could you please tell me which email address you are using to log in? Also, are you receiving the password reset emails?',
          timestamp: '2025-03-20T11:30:00Z',
          attachments: [],
          isPrivate: false
        },
        {
          id: '3',
          from: 'customer',
          sender: 'John Smith',
          content: 'I\'m using john@example.com to log in and yes, I am receiving the password reset emails. I\'ve tried clicking the link and setting a new password multiple times, but I still can\'t log in.',
          timestamp: '2025-03-20T13:45:00Z',
          attachments: [
            { id: '1', name: 'screenshot.png', type: 'image/png', size: '250KB' }
          ]
        },
        {
          id: '4',
          from: 'agent',
          sender: 'Sarah Kim',
          content: 'Thanks for confirming. Let me check if there are any account locks or other issues with your account.',
          timestamp: '2025-03-21T09:20:00Z',
          attachments: [],
          isPrivate: false
        },
        {
          id: '5',
          from: 'agent',
          sender: 'Sarah Kim',
          content: 'I checked with the technical team and there appears to be a caching issue. We\'ll need to clear your account cache from our side.',
          timestamp: '2025-03-21T14:30:00Z',
          attachments: [],
          isPrivate: true
        }
      ],
      notes: [
        {
          id: '1',
          author: 'Sarah Kim',
          content: 'Contacted technical team about caching issue. They confirmed problem and are working on a fix.',
          timestamp: '2025-03-21T10:15:00Z'
        },
        {
          id: '2',
          author: 'Michael Johnson',
          content: 'This is the third similar report this week. We should consider a system-wide cache reset.',
          timestamp: '2025-03-21T11:30:00Z'
        }
      ]
    }
  };
  
  // Fetch ticket details
  useEffect(() => {
    const fetchTicketDetails = async () => {
      setLoading(true);
      setError(null);
      
      if (!ticketId) {
        setLoading(false);
        return;
      }
      
      try {
        // Try to fetch from Firestore if user is authenticated
        if (currentUser) {
          try {
            const ticketRef = doc(db, 'tickets', ticketId);
            
            // Set up real-time listener with error handling
            const unsubscribe = onSnapshot(ticketRef, 
              (docSnapshot) => {
                if (docSnapshot.exists()) {
                  const data = docSnapshot.data();
                  // Format date fields
                  const formattedTicket = {
                    id: docSnapshot.id,
                    ...data,
                    createdAt: data.createdAt?.toDate() || new Date(),
                    updatedAt: data.updatedAt?.toDate() || new Date(),
                    dueDate: data.dueDate?.toDate() || null,
                    // Make sure we have the right structure for conversations
                    conversation: data.conversation || { messages: [], notes: [] }
                  };
                  
                  setTicket(formattedTicket);
                  setLoading(false);
                } else {
                  // No document found, fall back to mock data
                  console.log("Ticket not found in Firestore, using mock data");
                  setTicket(mockTicket);
                  setLoading(false);
                }
              },
              (err) => {
                // Error with listener, fall back to mock data
                console.error("Error with Firestore listener:", err);
                console.log("Falling back to mock data due to Firestore error");
                setTicket(mockTicket);
                setLoading(false);
              }
            );
            
            // Clean up listener on unmount
            return () => unsubscribe();
          } catch (err) {
            // If onSnapshot fails entirely, use mock data
            console.error("Failed to set up Firestore listener:", err);
            setTicket(mockTicket);
            setLoading(false);
          }
        } else {
          // No authenticated user, use mock data
          console.log("No authenticated user, using mock data");
          setTicket(mockTicket);
          setLoading(false);
        }
      } catch (err) {
        // Catch any other errors and use mock data
        console.error("General error fetching ticket:", err);
        setTicket(mockTicket);
        setLoading(false);
      }
    };

    fetchTicketDetails();
  }, [ticketId, currentUser]);

  // Format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Get status display info
  const getStatusInfo = (status) => {
    switch (status) {
      case 'new':
        return { label: 'New', color: 'bg-blue-100 text-blue-800' };
      case 'in_progress':
        return { label: 'In Progress', color: 'bg-yellow-100 text-yellow-800' };
      case 'waiting':
        return { label: 'Waiting', color: 'bg-purple-100 text-purple-800' };
      case 'resolved':
        return { label: 'Resolved', color: 'bg-green-100 text-green-800' };
      case 'closed':
        return { label: 'Closed', color: 'bg-gray-100 text-gray-800' };
      default:
        return { label: status, color: 'bg-gray-100 text-gray-800' };
    }
  };

  // Get priority display info
  const getPriorityInfo = (priority) => {
    switch (priority) {
      case 'high':
        return { label: 'High', color: 'text-red-600' };
      case 'medium':
        return { label: 'Medium', color: 'text-orange-600' };
      case 'low':
        return { label: 'Low', color: 'text-green-600' };
      default:
        return { label: priority, color: 'text-gray-600' };
    }
  };

  const uploadFileToStorage = async (file) => {
    return new Promise((resolve, reject) => {
      if (!currentUser) {
        reject(new Error("User not authenticated"));
        return;
      }
      
      try {
        // Create storage reference
        const storageRef = ref(storage, `ticket-attachments/${currentUser.uid}/${Date.now()}_${file.name}`);
        
        // Upload file
        const uploadTask = uploadBytesResumable(storageRef, file);
        
        // Monitor upload
        uploadTask.on('state_changed', 
          (snapshot) => {
            // Track progress
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(prev => ({
              ...prev,
              [file.name]: progress
            }));
          },
          (error) => {
            console.error("Upload failed:", error);
            reject(error);
          },
          async () => {
            // Get download URL on completion
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve({
              name: file.name,
              type: file.type,
              size: `${Math.round(file.size / 1024)}KB`,
              url: downloadURL
            });
          }
        );
      } catch (error) {
        console.error("Error initiating upload:", error);
        reject(error);
      }
    });
  };

  // Handle file upload
  const handleFileUpload = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const fileArray = Array.from(files);
    
    try {
      // Create temporary attachment entries with loading state
      const tempAttachments = fileArray.map(file => ({
        id: Date.now().toString() + Math.random().toString(36).substring(2),
        name: file.name,
        type: file.type,
        size: `${Math.round(file.size / 1024)}KB`,
        isUploading: true
      }));
      
      setReplyAttachments(prev => [...prev, ...tempAttachments]);
      
      // Upload files to Firebase Storage
      const uploadPromises = fileArray.map(file => uploadFileToStorage(file));
      const uploadedFiles = await Promise.all(uploadPromises);
      
      // Replace temporary attachments with uploaded ones
      setReplyAttachments(prev => {
        // Remove temp attachments
        const filteredAttachments = prev.filter(
          att => !tempAttachments.some(temp => temp.id === att.id)
        );
        
        // Add uploaded attachments
        return [
          ...filteredAttachments,
          ...uploadedFiles.map(file => ({
            id: Date.now().toString() + Math.random().toString(36).substring(2),
            name: file.name,
            type: file.type,
            size: file.size,
            url: file.url
          }))
        ];
      });
    } catch (error) {
      console.error('Error uploading files:', error);
      setError('Failed to upload one or more files');
    }
    
    // Reset input
    e.target.value = '';
  };

  // Handle status change
  const handleStatusChange = async (newStatus) => {
    if (!ticket) return;
    
    // Update locally first for immediate UI feedback
    setTicket(prev => ({
      ...prev,
      status: newStatus,
      lastUpdated: new Date().toISOString()
    }));
    
    // Try to update in Firestore if authenticated
    if (currentUser) {
      try {
        const ticketRef = doc(db, 'tickets', ticketId);
        
        await updateDoc(ticketRef, {
          status: newStatus,
          updatedAt: serverTimestamp()
        });
        
        // The UI will automatically update due to the onSnapshot listener
      } catch (error) {
        console.error('Error updating status:', error);
        // Don't show error in demo mode, as the local state is already updated
      }
    }
  };

  // Handle message sending
  const handleSendReply = async () => {
    if ((!newReply.trim() && replyAttachments.length === 0) || !ticket) return;
    
    setSending(true);
    
    // Create new message
    const newMessage = {
      id: Date.now().toString(),
      from: 'agent',
      sender: currentUser?.displayName || 'Support Agent',
      content: newReply,
      timestamp: new Date().toISOString(),
      attachments: replyAttachments,
      isPrivate: isPrivateReply
    };
    
    // Update locally first for immediate UI feedback
    setTicket(prev => {
      if (isPrivateReply) {
        return {
          ...prev,
          conversation: {
            ...prev.conversation,
            notes: [...(prev.conversation?.notes || []), newMessage]
          },
          lastUpdated: new Date().toISOString()
        };
      } else {
        return {
          ...prev,
          conversation: {
            ...prev.conversation,
            messages: [...(prev.conversation?.messages || []), newMessage]
          },
          lastUpdated: new Date().toISOString()
        };
      }
    });
    
    // Try to update in Firestore if authenticated
    if (currentUser) {
      try {
        const ticketRef = doc(db, 'tickets', ticketId);
        
        // Upload attachments first if any
        const uploadedAttachments = [...replyAttachments];
        if (replyAttachments.length > 0) {
          for (let i = 0; i < replyAttachments.length; i++) {
            const attachment = replyAttachments[i];
            // Only upload if it's a file object and not already uploaded
            if (attachment.file && !attachment.url) {
              try {
                const uploadedFile = await uploadFileToStorage(attachment.file);
                uploadedAttachments[i] = uploadedFile;
              } catch (error) {
                console.error('Error uploading attachment:', error);
              }
            }
          }
        }
        
        // Update message with uploaded attachments
        newMessage.attachments = uploadedAttachments;
        
        // Update the ticket in Firestore
        if (isPrivateReply) {
          await updateDoc(ticketRef, {
            'conversation.notes': arrayUnion(newMessage),
            messages: (ticket.conversation?.messages?.length || 0) + (ticket.conversation?.notes?.length || 0) + 1,
            updatedAt: serverTimestamp()
          });
        } else {
          await updateDoc(ticketRef, {
            'conversation.messages': arrayUnion(newMessage),
            messages: (ticket.conversation?.messages?.length || 0) + (ticket.conversation?.notes?.length || 0) + 1,
            updatedAt: serverTimestamp()
          });
        }
      } catch (error) {
        console.error('Error sending reply to Firestore:', error);
        // Don't show error in demo mode, as the local state is already updated
      }
    }
    
    // Clear form
    setNewReply('');
    setReplyAttachments([]);
    setIsPrivateReply(false);
    setSending(false);
  };

  // Handle remove attachment
  const handleRemoveAttachment = (id) => {
    setReplyAttachments(prev => prev.filter(att => att.id !== id));
  };

  
  const handleDelete = async () => {
    if (!ticketId) return;
    
    try {
      // Delete the ticket document
      await deleteDoc(doc(db, 'tickets', ticketId));
      
      // Navigate back to the tickets list
      navigate('/crm/support');
    } catch (error) {
      console.error('Error deleting ticket:', error);
      setError("Failed to delete ticket");
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Ticket not found</h3>
          <p className="text-gray-500 mb-6">The support ticket you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/crm/support')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Tickets
          </button>
        </div>
      </div>
    );
  }

  const statusInfo = getStatusInfo(ticket.status);
  const priorityInfo = getPriorityInfo(ticket.priority);

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/crm/support"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Tickets
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/crm/support/edit/${ticket.id}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Ticket Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-start gap-6">
            <div className="flex-1">
              <div className="flex items-center gap-3 mb-3">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusInfo(ticket.status).color}`}>
                  {getStatusInfo(ticket.status).label}
                </span>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800`}>
                  {ticket.category}
                </span>
                <span className={`inline-flex items-center text-xs font-medium ${getPriorityInfo(ticket.priority).color}`}>
                  <AlertCircle size={14} className="mr-1" />
                  {getPriorityInfo(ticket.priority).label} Priority
                </span>
              </div>
              
              <h1 className="text-2xl font-bold text-gray-900 mb-2">{ticket.subject}</h1>
              
              <div className="flex items-center text-sm text-gray-500 mt-2">
                <div className="mr-4 flex items-center">
                  <Clock size={14} className="mr-1" />
                  Opened: {formatDate(ticket.createdAt)}
                </div>
                <div className="flex items-center">
                  <User size={14} className="mr-1" />
                  Assigned to: {ticket.assignedTo || 'Unassigned'}
                </div>
              </div>
            </div>
            
            <div className="sm:text-right bg-gray-50 p-4 rounded-lg">
              <div className="flex flex-col sm:items-end">
                <p className="text-sm font-medium text-gray-700 mb-1">Customer</p>
                <p className="text-gray-900 font-medium">{ticket.customer.name}</p>
                <div className="flex items-center text-sm text-gray-500 mt-1">
                  <Mail size={14} className="mr-1" />
                  {ticket.customer.email}
                </div>
                {ticket.customer.phone && (
                  <div className="flex items-center text-sm text-gray-500">
                    <Phone size={14} className="mr-1" />
                    {ticket.customer.phone}
                  </div>
                )}
                {ticket.customer.company && (
                  <div className="flex items-center text-sm text-gray-500">
                    <Building size={14} className="mr-1" />
                    {ticket.customer.company}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        
        {/* Status Actions Bar */}
        <div className="bg-gray-50 px-6 py-3 border-b border-gray-200">
          <div className="flex flex-wrap items-center gap-2">
            <span className="text-sm font-medium text-gray-700 mr-2">Update Status:</span>
            <button
              onClick={() => handleStatusChange('new')}
              className={`px-3 py-1.5 rounded-lg text-xs font-medium ${
                ticket.status === 'new' ? 'bg-blue-100 text-blue-800' : 'bg-white border border-gray-300 text-gray-600 hover:bg-gray-50'
              }`}
            >
              New
            </button>
            <button
              onClick={() => handleStatusChange('in_progress')}
              className={`px-3 py-1.5 rounded-lg text-xs font-medium ${
                ticket.status === 'in_progress' ? 'bg-yellow-100 text-yellow-800' : 'bg-white border border-gray-300 text-gray-600 hover:bg-gray-50'
              }`}
            >
              In Progress
            </button>
            <button
              onClick={() => handleStatusChange('waiting')}
              className={`px-3 py-1.5 rounded-lg text-xs font-medium ${
                ticket.status === 'waiting' ? 'bg-purple-100 text-purple-800' : 'bg-white border border-gray-300 text-gray-600 hover:bg-gray-50'
              }`}
            >
              Waiting
            </button>
            <button
              onClick={() => handleStatusChange('resolved')}
              className={`px-3 py-1.5 rounded-lg text-xs font-medium ${
                ticket.status === 'resolved' ? 'bg-green-100 text-green-800' : 'bg-white border border-gray-300 text-gray-600 hover:bg-gray-50'
              }`}
            >
              Resolved
            </button>
            <button
              onClick={() => handleStatusChange('closed')}
              className={`px-3 py-1.5 rounded-lg text-xs font-medium ${
                ticket.status === 'closed' ? 'bg-gray-100 text-gray-800' : 'bg-white border border-gray-300 text-gray-600 hover:bg-gray-50'
              }`}
            >
              Closed
            </button>
          </div>
        </div>
        
        {/* Message Conversation */}
        <div className="p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Conversation</h3>
          
          <div className="space-y-6 mb-8">
            {ticket.conversation && ticket.conversation.messages && 
              ticket.conversation.messages.map((message) => (
                <div 
                  key={message.id} 
                  className={`rounded-lg p-4 ${
                    message.from === 'customer' 
                      ? 'bg-blue-50 border border-blue-100' 
                      : message.isPrivate
                        ? 'bg-amber-50 border border-amber-100'
                        : 'bg-gray-50 border border-gray-200'
                  }`}
                >
                  <div className="flex justify-between mb-2">
                    <div className="flex items-center">
                      <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white ${
                        message.from === 'customer' ? 'bg-blue-500' : 'bg-[#190192]'
                      }`}>
                        {message.from === 'customer' ? <User size={16} /> : <MessageCircle size={16} />}
                      </div>
                      <div className="ml-2">
                        <p className="text-sm font-medium">
                          {message.sender}
                          {message.isPrivate && (
                            <span className="ml-2 text-xs px-1.5 py-0.5 bg-amber-100 text-amber-800 rounded">Internal Note</span>
                          )}
                        </p>
                        <p className="text-xs text-gray-500">{formatDate(message.timestamp)}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="text-sm text-gray-700 whitespace-pre-line mt-2">
                    {message.content}
                  </div>
                  
                  {message.attachments && message.attachments.length > 0 && (
                    <div className="mt-3 pt-3 border-t border-gray-200">
                      <p className="text-xs text-gray-500 mb-2">Attachments:</p>
                      <div className="flex flex-wrap gap-2">
                        {message.attachments.map((attachment, index) => (
                          <div key={index} className="bg-white rounded p-2 border flex items-center">
                            {attachment.type?.startsWith('image/') ? (
                              <Image size={14} className="text-blue-500 mr-1.5" />
                            ) : (
                              <Paperclip size={14} className="text-blue-500 mr-1.5" />
                            )}
                            <span className="text-xs truncate max-w-[150px]">{attachment.name}</span>
                            <button 
                              className="ml-1.5 text-gray-400 hover:text-[#190192]"
                              title="Download"
                            >
                              <Download size={12} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))
            }
          </div>
          
          {/* Reply Box */}
          {ticket.status !== 'closed' && (
            <div className="bg-white border rounded-lg overflow-hidden">
              <div className="px-4 py-3 border-b border-gray-200 flex items-center justify-between">
                <h4 className="text-sm font-medium text-gray-900">Reply to Ticket</h4>
                <div className="flex items-center">
                  <label className="flex items-center text-sm text-gray-600 mr-2">
                    <input
                      type="checkbox"
                      className="mr-1.5 h-4 w-4 text-[#190192] rounded border-gray-300 focus:ring-[#190192]"
                      checked={isPrivateReply}
                      onChange={(e) => setIsPrivateReply(e.target.checked)}
                    />
                    Internal Note
                  </label>
                </div>
              </div>
              
              <textarea
                className="w-full p-4 text-sm text-gray-700 border-none focus:ring-0"
                rows="5"
                placeholder="Type your message here..."
                value={newReply}
                onChange={(e) => setNewReply(e.target.value)}
              ></textarea>
              
              {replyAttachments.length > 0 && (
                <div className="px-4 py-2 bg-gray-50 border-t border-gray-200">
                  <p className="text-xs text-gray-500 mb-2">Attachments:</p>
                  <div className="flex flex-wrap gap-2">
                    {replyAttachments.map((attachment) => (
                      <div key={attachment.id} className="bg-white rounded p-2 border flex items-center">
                        {attachment.type?.startsWith('image/') ? (
                          <Image size={14} className="text-blue-500 mr-1.5" />
                        ) : (
                          <Paperclip size={14} className="text-blue-500 mr-1.5" />
                        )}
                        <span className="text-xs truncate max-w-[150px]">{attachment.name}</span>
                        <button 
                          onClick={() => handleRemoveAttachment(attachment.id)}
                          className="ml-1.5 text-gray-400 hover:text-red-500"
                        >
                          <X size={12} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              <div className="p-3 flex items-center justify-between bg-gray-50 border-t border-gray-200">
                <div>
                  <input
                    type="file"
                    id="file-upload"
                    className="hidden"
                    onChange={handleFileUpload}
                    multiple
                  />
                  <label 
                    htmlFor="file-upload" 
                    className="cursor-pointer inline-flex items-center px-3 py-1.5 bg-white border border-gray-300 rounded text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <Plus size={14} className="mr-1.5" />
                    Add Attachment
                  </label>
                </div>
                
                <button
                  onClick={handleSendReply}
                  disabled={(!newReply.trim() && replyAttachments.length === 0) || sending}
                  className={`inline-flex items-center px-4 py-2 rounded ${
                    sending || (!newReply.trim() && replyAttachments.length === 0)
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-[#190192] text-white hover:bg-[#2C0DB5]'
                  }`}
                >
                  {sending ? (
                    <>
                      <div className="animate-spin mr-2 h-4 w-4 border-2 border-gray-200 rounded-full border-t-white"></div>
                      Sending...
                    </>
                  ) : (
                    <>
                      <Send size={14} className="mr-1.5" /> 
                      {isPrivateReply ? 'Add Internal Note' : 'Send Reply'}
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
          
          {/* Agent Notes */}
          {ticket.conversation && ticket.conversation.notes && ticket.conversation.notes.length > 0 && (
            <div className="mt-8">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Internal Notes</h3>
              
              <div className="space-y-4">
                {ticket.conversation.notes.map((note) => (
                  <div key={note.id} className="bg-amber-50 border border-amber-100 rounded-lg p-4">
                    <div className="flex justify-between mb-2">
                      <p className="text-sm font-medium text-gray-900">{note.author}</p>
                      <p className="text-xs text-gray-500">{formatDate(note.timestamp)}</p>
                    </div>
                    <p className="text-sm text-gray-700">{note.content}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Ticket</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this support ticket? This action cannot be undone and all messages and notes will be permanently removed.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TicketDetail;