// src/components/human-capital/recruitment/JobList.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, Briefcase, 
  Calendar, Users, Tag, ExternalLink, AlertCircle, MapPin 
} from 'lucide-react';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useAuth } from '../../../context/AuthContext';
import { AppContext } from '../../../context/AppContext';

const JobList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  
  const { currentUser } = useAuth();
  const { activeBusiness } = useContext(AppContext);

  // Fetch jobs
  useEffect(() => {
    const fetchJobs = async () => {
      if (!currentUser || !activeBusiness?.id) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        // Query Firestore for jobs in the active business
        const jobsQuery = query(
          collection(db, 'job_postings'),
          where('businessId', '==', activeBusiness.id),
          orderBy('createdAt', 'desc')
        );
        
        const querySnapshot = await getDocs(jobsQuery);
        const jobsData = [];
        
        querySnapshot.forEach((doc) => {
          jobsData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        
        setJobs(jobsData);
      } catch (err) {
        console.error('Error fetching jobs:', err);
        setError('Failed to load job postings. Please try again later.');
        
        // If in development or no data yet, use mock data
        if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_USE_MOCK_DATA === 'true') {
          setJobs([
            {
              id: '1',
              title: 'Senior Software Engineer',
              department: 'Engineering',
              location: 'San Francisco, CA',
              type: 'Full-time',
              status: 'open',
              createdAt: new Date('2025-03-01'),
              closingDate: '2025-04-15',
              applicants: 12
            },
            {
              id: '2',
              title: 'Marketing Manager',
              department: 'Marketing',
              location: 'New York, NY',
              type: 'Full-time',
              status: 'open',
              createdAt: new Date('2025-03-05'),
              closingDate: '2025-04-05',
              applicants: 8
            },
            {
              id: '3',
              title: 'Financial Analyst',
              department: 'Finance',
              location: 'Chicago, IL',
              type: 'Full-time',
              status: 'closed',
              createdAt: new Date('2025-02-15'),
              closingDate: '2025-03-15',
              applicants: 15
            },
            {
              id: '4',
              title: 'Customer Support Specialist',
              department: 'Customer Support',
              location: 'Remote',
              type: 'Part-time',
              status: 'open',
              createdAt: new Date('2025-03-10'),
              closingDate: '2025-04-10',
              applicants: 5
            },
            {
              id: '5',
              title: 'UX Designer',
              department: 'Product',
              location: 'Boston, MA',
              type: 'Contract',
              status: 'draft',
              createdAt: new Date('2025-03-15'),
              closingDate: null,
              applicants: 0
            }
          ]);
        }
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchJobs();
  }, [currentUser, activeBusiness?.id]);

  // Get filtered jobs
  const getFilteredJobs = () => {
    let filtered = jobs;
    
    // Filter by search term
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(job => 
        job.title.toLowerCase().includes(term) ||
        job.department?.toLowerCase().includes(term) ||
        job.location?.toLowerCase().includes(term) ||
        job.type?.toLowerCase().includes(term)
      );
    }
    
    // Filter by status
    if (filterStatus !== 'all') {
      filtered = filtered.filter(job => job.status === filterStatus);
    }
    
    return filtered;
  };

  // Format date
  const formatDate = (date) => {
    if (!date) return 'N/A';
    
    try {
      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
      
      // Handle Firestore timestamp
      if (date.toDate && typeof date.toDate === 'function') {
        return date.toDate().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
      
      // Handle string dates
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return date.toString();
    }
  };

  // Get status badge styles
  const getStatusBadge = (status) => {
    switch (status) {
      case 'open':
        return 'bg-green-100 text-green-800';
      case 'closed':
        return 'bg-red-100 text-red-800';
      case 'draft':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search jobs by title, department, or location..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Statuses</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
            <option value="draft">Draft</option>
          </select>
          
          <Link
            to="/human-capital/recruitment/jobs/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Create Job Posting
          </Link>
        </div>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 rounded-lg py-6 px-6 text-center">
          <AlertCircle size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Error Loading Jobs</h3>
          <p className="text-gray-600 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
          >
            Try Again
          </button>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {jobs.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Briefcase size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No job postings found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't created any job postings yet. Click the 'Create Job Posting' button to get started.
              </p>
              <Link
                to="/human-capital/recruitment/jobs/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Create Your First Job Posting
              </Link>
            </div>
          ) : getFilteredJobs().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <Briefcase size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching job postings</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any job postings matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterStatus('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              {getFilteredJobs().map(job => (
                <div 
                  key={job.id}
                  className="bg-white rounded-xl shadow-sm border border-gray-200 hover:shadow-md transition-shadow overflow-hidden"
                >
                  <div className="p-6">
                    <div className="flex flex-col md:flex-row md:items-start justify-between gap-4">
                      <div className="flex-1">
                        <div className="flex flex-wrap items-center gap-2 mb-2">
                          <h3 className="text-lg font-medium text-gray-900">{job.title}</h3>
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadge(job.status)}`}>
                            {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
                          </span>
                        </div>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-2 mb-4">
                          {job.department && (
                            <div className="flex items-center text-sm text-gray-600">
                              <Briefcase size={16} className="mr-2 text-gray-400" />
                              <span>{job.department}</span>
                            </div>
                          )}
                          
                          {job.location && (
                            <div className="flex items-center text-sm text-gray-600">
                              <MapPin size={16} className="mr-2 text-gray-400" />
                              <span>{job.location}</span>
                            </div>
                          )}
                          
                          {job.type && (
                            <div className="flex items-center text-sm text-gray-600">
                              <Tag size={16} className="mr-2 text-gray-400" />
                              <span>{job.type}</span>
                            </div>
                          )}
                          
                          {job.closingDate && (
                            <div className="flex items-center text-sm text-gray-600">
                              <Calendar size={16} className="mr-2 text-gray-400" />
                              <span>Closes: {formatDate(job.closingDate)}</span>
                            </div>
                          )}
                        </div>
                        
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center">
                            <Users size={16} className="mr-1.5 text-[#190192]" />
                            <span className="text-sm font-medium">{job.applicants || 0} Applicants</span>
                          </div>
                          
                          {job.status === 'open' && (
                            <a 
                              href="#"
                              className="text-sm text-[#190192] hover:text-[#2C0DB5] flex items-center"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Public Posting <ExternalLink size={14} className="ml-1" />
                            </a>
                          )}
                        </div>
                      </div>
                      
                      <div className="flex md:flex-col gap-3 md:items-end">
                        <Link
                          to={`/human-capital/recruitment/jobs/${job.id}`}
                          className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                        >
                          View Details
                        </Link>
                        
                        <Link
                          to={`/human-capital/recruitment/applicants?jobId=${job.id}`}
                          className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 flex items-center"
                        >
                          <Users size={16} className="mr-2" />
                          Applicants
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default JobList;