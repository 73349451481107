import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { 
  BarChart2, ShoppingCart, Package, Truck, DollarSign, 
  Users, CheckCircle, Calendar, MessageSquare, FileText, Briefcase,
  Grid, Search, Bell, LogOut, Settings, Menu, X, User, Database,
  PieChart, Tag, HelpCircle, Moon, Sun, ChevronRight, ChevronLeft, Target, Home
} from 'lucide-react';

import logo from '../assets/images/logo.png';
import { useAuth } from '../context/AuthContext';
import DiscountCountdown from '../components/subscription/DiscountCountdown';

const MainLayout = () => {
  // Default open on desktop, closed on mobile
  const [sidebarOpen, setSidebarOpen] = useState(
    typeof window !== 'undefined' ? window.innerWidth >= 1024 : true
  );


  const location = useLocation();
  const { logout, userProfile, currentUser, isDemoMode, disableDemoMode } = useAuth();
  
  const mainNavigation = [
    { name: 'Dashboard', icon: BarChart2, id: 'dashboard', path: '/dashboard' },
    { name: 'Records', icon: Database, id: 'records', path: '/records' },
    { name: 'Meetings', icon: Calendar, id: 'meetings', path: '/meetings' },
    { name: 'Communication', icon: MessageSquare, id: 'communication', path: '/communication' },
    { name: 'Planner', icon: Target, id: 'plans', path: '/plans' },
    { name: 'Tasks', icon: CheckCircle, id: 'tasks', path: '/tasks' },
    { name: 'Inventory', icon: Package, id: 'inventory', path: '/inventory' },
    { name: 'CRM', icon: ShoppingCart, id: 'crm', path: '/crm' },
    { name: 'Orders', icon: Truck, id: 'orders', path: '/orders' },
    { name: 'Finance', icon: DollarSign, id: 'finance', path: '/finance' }
  ];

  const secondaryNavigation = [
    { name: 'Business', icon: Briefcase, id: 'business', path: '/business' },
    { name: 'Human Capital', icon: Users, id: 'human-capital', path: '/human-capital' },
    { name: 'Marketing', icon: Tag, id: 'marketing', path: '/marketing' },
    { name: 'Customers', icon: Users, id: 'customers', path: '/customers' },
    { name: 'Calendar', icon: Calendar, id: 'calendar', path: '/calendar' },
    { name: 'Messages', icon: MessageSquare, id: 'messages', path: '/messages' }
  ];

  // Determine active navigation item
  const getActivePath = () => {
    const path = location.pathname.split('/')[1] || 'dashboard';
    return path;
  };
  const activeNav = getActivePath();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  const getPageTitle = () => {
    const mainNav = mainNavigation.find(nav => nav.id === activeNav);
    const secondaryNav = secondaryNavigation.find(nav => nav.id === activeNav);
    if (mainNav) return mainNav.name;
    if (secondaryNav) return secondaryNav.name;
    return 'BizCentral';
  };

  // Close sidebar on mobile after navigating
  const handleMenuItemClick = () => {
    if (window.innerWidth < 1024) {
      setSidebarOpen(false);
    }
  };

  const getInitials = () => {
    if (userProfile) {
      return `${userProfile.firstName?.charAt(0) || ''}${userProfile.lastName?.charAt(0) || ''}`;
    }
    return currentUser?.email?.charAt(0).toUpperCase() || 'U';
  };
  
  const getUserFullName = () => {
    if (userProfile) {
      return `${userProfile.title || ''} ${userProfile.firstName || ''} ${userProfile.lastName || ''}`.trim();
    }
    return currentUser?.email || 'User';
  };

  // Sidebar contents (shared between desktop & mobile)
  const SidebarContent = () => (
    <>
      <div className="px-6 py-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="w-10 h-10 rounded-xl bg-white flex items-center justify-center">
              <Home className="h-6 w-6 text-[#190192]" />
            </div>
            <div className="flex items-center justify-between w-full">
              <Link to="/" className="flex-shrink-0 flex items-center">
                <img className="h-8 w-auto" src={logo} alt="BizCentral" />
              </Link>
            </div>
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="hidden lg:inline-flex mr-4 p-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            >
              {sidebarOpen ? <ChevronLeft size={22} /> : ''}
            </button>
          </div>
          {/* Mobile close button */}
          <button
            onClick={() => setSidebarOpen(false)}
            className="text-gray-500 hover:text-gray-700 lg:hidden"
          >
            <X size={20} />
          </button>
        </div>
      </div>
      
      <div className="px-4 mb-6">
        <div className="relative">
          <Search
            size={16}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <input
            type="text"
            placeholder="Search..."
            className="pl-10 pr-4 py-2.5 w-full rounded-xl text-sm border border-gray-200
              focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20
              bg-white focus:outline-none transition-all"
          />
        </div>
      </div>

      <nav className="flex-1 px-4 space-y-6 overflow-y-auto">
        <div>
          <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider">
            Activity
          </h3>
          <ul className="mt-3 space-y-1">
            {mainNavigation.map(item => (
              <li key={item.id}>
                <Link
                  to={item.path}
                  onClick={handleMenuItemClick}
                  className={`flex items-center w-full px-4 py-2.5 rounded-xl ${
                    activeNav === item.id
                      ? 'bg-[#190192] text-white'
                      : 'text-gray-700 hover:bg-purple-50'
                  } transition-colors duration-150`}
                >
                  <item.icon size={18} className="mr-3" />
                  <span>{item.name}</span>
                  {activeNav === item.id && (
                    <span className="ml-auto">
                      <ChevronRight size={16} />
                    </span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="px-4 text-xs font-semibold text-gray-500 uppercase tracking-wider">
            Business
          </h3>
          <ul className="mt-3 space-y-1">
            {secondaryNavigation.map(item => (
              <li key={item.id}>
                <Link
                  to={item.path}
                  onClick={handleMenuItemClick}
                  className={`flex items-center w-full px-4 py-2.5 rounded-xl ${
                    activeNav === item.id
                      ? 'bg-[#190192] text-white'
                      : 'text-gray-700 hover:bg-purple-50'
                  } transition-colors duration-150`}
                >
                  <item.icon size={18} className="mr-3" />
                  <span>{item.name}</span>
                  {activeNav === item.id && (
                    <span className="ml-auto">
                      <ChevronRight size={16} />
                    </span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>

      <div className="p-4 border-t border-gray-200">
        <div className="flex items-center p-3 bg-gray-50 rounded-xl">
        <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
              {getInitials()}
            </div>
          <div className="ml-3 flex-1">
          <p className="text-sm font-medium text-gray-800">{getUserFullName()}</p>
          <p className="text-xs text-gray-500">{userProfile?.userRole || 'User'}</p>
          </div>
          <div className="flex">
            <Link
              to="/profile"
              className="p-1.5 rounded-lg text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            >
              <Settings size={18} />
            </Link>
            <button
              onClick={handleLogout}
              className="p-1.5 rounded-lg text-gray-500 hover:text-gray-700 hover:bg-gray-100 ml-1"
            >
              <LogOut size={18} />
            </button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="flex h-screen bg-gray-50 text-gray-800">
      {/* DESKTOP SIDEBAR (shown for lg: and above) */}
      <aside
        className={`
          hidden lg:flex flex-col border-r border-gray-200 bg-white
          transition-all duration-300 ease-in-out
          ${sidebarOpen ? 'w-72' : 'w-0'}
        `}
      >
        {sidebarOpen && <SidebarContent />}
      </aside>

      {/* MOBILE SIDEBAR (shown below lg:) */}
      <aside
        className={`
          lg:hidden fixed inset-y-0 left-0 z-50 w-72 transform
          bg-white border-r shadow-lg
          transition-transform duration-300 ease-in-out
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        `}
      >
        <SidebarContent />
      </aside>

      {/* MAIN CONTENT */}
      <div
        className={`
          flex-1 flex flex-col overflow-hidden
          transition-all duration-300
          ${/* If desktop and the sidebar is open, shift content right by 72px */''}
          ${sidebarOpen ? 'lg:ml-50' : 'lg:ml-0'}
        `}
      >
        {/* Topbar */}
        <header className="h-16 bg-white border-b border-gray-200 flex items-center px-4 lg:px-6">
          <div className="flex items-center lg:w-80">
            {/* Desktop toggle button */}
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className="hidden lg:inline-flex mr-4 p-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            >
              {!sidebarOpen ? <Menu size={22} /> : '' }
            </button>
            
            {/* Mobile open button */}
            <button
              onClick={() => setSidebarOpen(true)}
              className="lg:hidden mr-4 p-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            >
              <Menu size={22} />
            </button>
            <h2 className="text-xl font-bold text-gray-800">{getPageTitle()}</h2>
          </div>

          <div className="flex-1 flex justify-end items-center space-x-4">
            <div className="hidden md:flex items-center">
              <button className="p-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100 mr-2">
                <Moon size={20} />
              </button>
              <button className="p-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100 mr-2">
                <Grid size={20} />
              </button>
              <button className="p-2 rounded-lg text-gray-600 hover:text-gray-900 hover:bg-gray-100 relative">
                <Bell size={20} />
                <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full"></span>
              </button>
            </div>

            <div className="border-l border-gray-200 h-8 hidden md:block"></div>

            <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
                  {getInitials()}
                </div>
                <span className="hidden md:block">{getUserFullName()}</span>
            </div>
          </div>
        </header>

        {/* Content Area */}
        <main className="flex-1 overflow-y-auto bg-gray-50 p-4 lg:p-2">
        {isDemoMode && (
            <div className="mb-6 bg-amber-50 border border-amber-200 rounded-xl p-4 shadow-sm">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-amber-800 font-medium">Demo Mode Active</h3>
                  <p className="text-amber-700 text-sm">
                    You're viewing sample data. Data modifications are disabled in demo mode.
                  </p>
                </div>
                <button
                  onClick={disableDemoMode}
                  className="px-4 py-2 bg-white border border-amber-300 rounded-lg text-amber-700 hover:bg-amber-100 text-sm font-medium"
                >
                  Exit Demo Mode
                </button>
              </div>
            </div>
          )}
                  <Outlet />
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
