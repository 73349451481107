// src/components/communication/email/BusinessEmailProfileForm.js
import React, { useState, useEffect } from 'react';
import { 
  Save, AlertCircle, Mail, CheckCircle, Loader,
  Info, Shield, ArrowLeft, AtSign, X, Upload, Globe
} from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { db, storage } from '../../../firebase/config';
import { doc, getDoc, setDoc, updateDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

const BusinessEmailProfileForm = () => {
  const { currentUser, userProfile } = useAuth();
  const { id } = useParams(); // 'new' or businessId
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  const [formData, setFormData] = useState({
    businessName: '',
    businessDomain: '',
    primaryContactName: '',
    primaryContactEmail: '',
    contactPhone: '',
    industry: '',
    emailVolume: 'low', // low, medium, high
    marketingConsent: false,
    privacyPolicyAccepted: false,
    defaultSenderName: '',
    defaultSenderEmail: '',
    defaultReplyToEmail: '',
    logo: null,
    logoUrl: '',
    hasCustomBranding: false,
    brandColors: {
      primary: '#190192',
      secondary: '#FFFFFF',
      accent: '#2C0DB5'
    },
    businessAddress: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      country: ''
    },
    emailProvider: 'default', // default, gmail, smtp
  });
  
  // Fetch existing data if editing
  useEffect(() => {
    const fetchBusinessData = async () => {
      if (id === 'new') {
        // For new profiles, pre-fill with user profile data if available
        if (userProfile) {
          setFormData(prev => ({
            ...prev,
            businessName: userProfile.businessName || '',
            primaryContactName: `${userProfile.firstName || ''} ${userProfile.lastName || ''}`.trim(),
            primaryContactEmail: currentUser?.email || '',
            defaultSenderName: userProfile.businessName || '',
            defaultSenderEmail: `info@${userProfile.businessDomain || 'company.com'}`
          }));
        }
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        setError(null);
        
        const businessDoc = await getDoc(doc(db, 'businesses', id));
        
        if (businessDoc.exists()) {
          const data = businessDoc.data();
          
          // Populate form with existing data
          setFormData({
            businessName: data.name || '',
            businessDomain: data.domain || '',
            primaryContactName: data.contactName || '',
            primaryContactEmail: data.contactEmail || '',
            contactPhone: data.contactPhone || '',
            industry: data.industry || '',
            emailVolume: data.emailVolume || 'low',
            marketingConsent: data.marketingConsent || false,
            privacyPolicyAccepted: true, // Already accepted if they have a profile
            defaultSenderName: data.emailSettings?.senderName || data.name || '',
            defaultSenderEmail: data.emailSettings?.senderEmail || '',
            defaultReplyToEmail: data.emailSettings?.replyToEmail || '',
            logoUrl: data.logoUrl || '',
            hasCustomBranding: data.hasCustomBranding || false,
            brandColors: data.brandColors || {
              primary: '#190192',
              secondary: '#FFFFFF',
              accent: '#2C0DB5'
            },
            businessAddress: data.address || {
              street: '',
              city: '',
              state: '',
              zipCode: '',
              country: ''
            },
            // src/components/communication/email/BusinessEmailProfileForm.js (continued)
            emailProvider: data.emailSettings?.useGmail ? 'gmail' : 
                           data.emailSettings?.useSmtp ? 'smtp' : 'default',
          });
        } else {
          setError("Business profile not found. Creating a new one.");
        }
      } catch (err) {
        console.error("Error loading business data:", err);
        setError("Failed to load business profile data.");
      } finally {
        setLoading(false);
      }
    };
    
    fetchBusinessData();
  }, [id, userProfile, currentUser]);
  
  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.startsWith('address.')) {
      const addressField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        businessAddress: {
          ...prev.businessAddress,
          [addressField]: value
        }
      }));
    } else if (name.startsWith('colors.')) {
      const colorField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        brandColors: {
          ...prev.brandColors,
          [colorField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
    
    // Clear messages
    setError(null);
    setSuccess(null);
  };
  
  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError("File is too large. Maximum size is 5MB.");
      return;
    }
    
    // Check file type (only images)
    if (!file.type.match('image.*')) {
      setError("Only image files are allowed.");
      return;
    }
    
    // Create preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData(prev => ({
        ...prev,
        logo: file,
        logoUrl: reader.result
      }));
    };
    reader.readAsDataURL(file);
  };
  
  // Handle email provider change
  const handleEmailProviderChange = (provider) => {
    setFormData(prev => ({
      ...prev,
      emailProvider: provider
    }));
  };
  
  // Validate form
  const validateForm = () => {
    if (!formData.businessName.trim()) {
      setError("Business name is required");
      return false;
    }
    
    if (!formData.primaryContactEmail.trim()) {
      setError("Contact email is required");
      return false;
    }
    
    if (!formData.defaultSenderName.trim()) {
      setError("Default sender name is required");
      return false;
    }
    
    if (!formData.defaultSenderEmail.trim()) {
      setError("Default sender email is required");
      return false;
    }
    
    if (!formData.privacyPolicyAccepted) {
      setError("You must accept the privacy policy to continue");
      return false;
    }
    
    return true;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    try {
      setSaving(true);
      setError(null);
      setSuccess(null);
      
      // Variable to store the logo URL
      let logoUrl = formData.logoUrl;
      
      // Upload logo to Firebase Storage if a new file was selected
      if (formData.logo && formData.logo instanceof File) {
        // Create a storage reference with a unique path
        const storageRef = ref(storage, `business-logos/${currentUser.uid}/${Date.now()}_${formData.logo.name}`);
        
        // Upload the file
        const uploadTask = uploadBytesResumable(storageRef, formData.logo);
        
        // Wait for the upload to complete
        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Track upload progress
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload progress: ${progress}%`);
            },
            (error) => {
              // Handle upload errors
              console.error("Upload error:", error);
              reject(error);
            },
            async () => {
              // Upload completed successfully, get the download URL
              try {
                logoUrl = await getDownloadURL(uploadTask.snapshot.ref);
                resolve();
              } catch (err) {
                reject(err);
              }
            }
          );
        });
        
        // If updating and there was a previous logo, delete it
        if (id !== 'new' && formData.logoUrl && formData.logoUrl.startsWith('https://firebasestorage.googleapis.com')) {
          try {
            // Extract the storage path from the URL (this is simplified, might need adjustment)
            const oldStoragePath = formData.logoUrl.split('business-logos/')[1];
            if (oldStoragePath) {
              const oldRef = ref(storage, `business-logos/${oldStoragePath}`);
              await deleteObject(oldRef);
            }
          } catch (deleteErr) {
            console.warn("Could not delete old logo", deleteErr);
            // Continue with the update, even if deleting the old file fails
          }
        }
      }
      
      let businessId = id;
      
      // If it's a new business, create a new document
      if (id === 'new') {
        const businessData = {
          name: formData.businessName,
          domain: formData.businessDomain,
          contactName: formData.primaryContactName,
          contactEmail: formData.primaryContactEmail,
          contactPhone: formData.contactPhone,
          industry: formData.industry,
          emailVolume: formData.emailVolume,
          marketingConsent: formData.marketingConsent,
          createdBy: currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          logoUrl: logoUrl, // Use the Firebase Storage URL
          hasCustomBranding: formData.hasCustomBranding,
          brandColors: formData.brandColors,
          address: formData.businessAddress,
          emailSettings: {
            senderName: formData.defaultSenderName,
            senderEmail: formData.defaultSenderEmail,
            replyToEmail: formData.defaultReplyToEmail,
            isVerified: false,
            verificationStatus: 'pending',
            unsubscribeLink: true,
            maxDailyEmails: 100,
            useGmail: formData.emailProvider === 'gmail',
            useSmtp: formData.emailProvider === 'smtp',
            smtpSettings: {
              host: '',
              port: '',
              username: '',
              password: '',
              useSsl: true
            }
          }
        };
        
        // Create a new document with a generated ID
        const businessesCollection = collection(db, 'businesses');
        const newBusinessRef = doc(businessesCollection);
        await setDoc(newBusinessRef, businessData);
        
        businessId = newBusinessRef.id;
      } else {
        // Get the current business document
        const businessDocRef = doc(db, 'businesses', id);
        const businessDoc = await getDoc(businessDocRef);
        
        // Update existing business
        const businessData = {
          name: formData.businessName,
          domain: formData.businessDomain,
          contactName: formData.primaryContactName,
          contactEmail: formData.primaryContactEmail,
          contactPhone: formData.contactPhone,
          industry: formData.industry,
          emailVolume: formData.emailVolume,
          marketingConsent: formData.marketingConsent,
          updatedAt: serverTimestamp(),
          logoUrl: logoUrl, // Use the Firebase Storage URL
          hasCustomBranding: formData.hasCustomBranding,
          brandColors: formData.brandColors,
          address: formData.businessAddress,
        };
        
        // Add email settings, preserving any existing values
        const existingData = businessDoc.exists() ? businessDoc.data() : {};
        const existingEmailSettings = existingData.emailSettings || {};
        
        businessData.emailSettings = {
          senderName: formData.defaultSenderName,
          senderEmail: formData.defaultSenderEmail,
          replyToEmail: formData.defaultReplyToEmail,
          // Preserve existing email settings
          isVerified: existingEmailSettings.isVerified || false,
          verificationStatus: existingEmailSettings.verificationStatus || 'pending',
          unsubscribeLink: existingEmailSettings.unsubscribeLink !== undefined ? existingEmailSettings.unsubscribeLink : true,
          maxDailyEmails: existingEmailSettings.maxDailyEmails || 100,
          useGmail: formData.emailProvider === 'gmail',
          useSmtp: formData.emailProvider === 'smtp',
          smtpSettings: existingEmailSettings.smtpSettings || {
            host: '',
            port: '',
            username: '',
            password: '',
            useSsl: true
          }
        };
        
        await updateDoc(businessDocRef, businessData);
      }
      
      setSuccess("Business email profile saved successfully");
      
      // Redirect to email settings page
      setTimeout(() => {
        navigate(`/communication/email/settings/${businessId}`);
      }, 1500);
      
    } catch (err) {
      console.error("Error saving business email profile:", err);
      setError("Failed to save business email profile: " + err.message);
    } finally {
      setSaving(false);
    }
  };
  
  return (
    <div className="bg-white rounded-xl shadow-md p-6">
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center">
          <button
            onClick={() => navigate('/communication/external')}
            className="mr-4 p-2 rounded-full text-gray-500 hover:bg-gray-100"
          >
            <ArrowLeft size={20} />
          </button>
          <h2 className="text-xl font-medium text-gray-900">
            {id === 'new' ? 'Create New Email Profile' : 'Edit Email Profile'}
          </h2>
        </div>
      </div>
      
      {/* Messages */}
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700 flex items-start">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}
      
      {success && (
        <div className="mb-6 bg-green-50 border-l-4 border-green-500 p-4 text-green-700 flex items-start">
          <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{success}</p>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Business Information */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Business Information</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="businessName" className="block text-sm font-medium text-gray-700 mb-1">
                Business Name <span className="text-red-500">*</span>
              </label>
              <input
                id="businessName"
                name="businessName"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.businessName}
                onChange={handleInputChange}
                required
              />
            </div>
            
            <div>
              <label htmlFor="businessDomain" className="block text-sm font-medium text-gray-700 mb-1">
                Business Domain
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                  <Globe size={16} />
                </span>
                <input
                  id="businessDomain"
                  name="businessDomain"
                  type="text"
                  className="block w-full rounded-r-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.businessDomain}
                  onChange={handleInputChange}
                  placeholder="yourdomain.com"
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Enter your domain name without https:// or www.
              </p>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="primaryContactName" className="block text-sm font-medium text-gray-700 mb-1">
                Primary Contact Name
              </label>
              <input
                id="primaryContactName"
                name="primaryContactName"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.primaryContactName}
                onChange={handleInputChange}
              />
            </div>
            
            <div>
              <label htmlFor="primaryContactEmail" className="block text-sm font-medium text-gray-700 mb-1">
                Primary Contact Email <span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                  <AtSign size={16} />
                </span>
                <input
                  id="primaryContactEmail"
                  name="primaryContactEmail"
                  type="email"
                  className="block w-full rounded-r-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.primaryContactEmail}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="contactPhone" className="block text-sm font-medium text-gray-700 mb-1">
                Contact Phone
              </label>
              <input
                id="contactPhone"
                name="contactPhone"
                type="tel"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.contactPhone}
                onChange={handleInputChange}
              />
            </div>
            
            <div>
              <label htmlFor="industry" className="block text-sm font-medium text-gray-700 mb-1">
                Industry
              </label>
              <select
                id="industry"
                name="industry"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.industry}
                onChange={handleInputChange}
              >
                <option value="">Select an industry</option>
                <option value="retail">Retail</option>
                <option value="technology">Technology</option>
                <option value="healthcare">Healthcare</option>
                <option value="education">Education</option>
                <option value="financial">Financial Services</option>
                <option value="manufacturing">Manufacturing</option>
                <option value="hospitality">Hospitality</option>
                <option value="consulting">Consulting</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
        </div>
        
        {/* Email Sending Settings */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Email Sending Settings</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label htmlFor="defaultSenderName" className="block text-sm font-medium text-gray-700 mb-1">
                Default Sender Name <span className="text-red-500">*</span>
              </label>
              <input
                id="defaultSenderName"
                name="defaultSenderName"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.defaultSenderName}
                onChange={handleInputChange}
                required
              />
              <p className="mt-1 text-xs text-gray-500">
                This name will appear as the sender of your emails
              </p>
            </div>
            
            <div>
              <label htmlFor="defaultSenderEmail" className="block text-sm font-medium text-gray-700 mb-1">
                Default Sender Email <span className="text-red-500">*</span>
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                  <Mail size={16} />
                </span>
                <input
                  id="defaultSenderEmail"
                  name="defaultSenderEmail"
                  type="email"
                  className="block w-full rounded-r-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.defaultSenderEmail}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Must be verified before sending emails
              </p>
            </div>
          </div>
          
          <div className="mb-6">
            <label htmlFor="defaultReplyToEmail" className="block text-sm font-medium text-gray-700 mb-1">
              Default Reply-To Email
            </label>
            <div className="flex">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                <Mail size={16} />
              </span>
              <input
                id="defaultReplyToEmail"
                name="defaultReplyToEmail"
                type="email"
                className="block w-full rounded-r-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.defaultReplyToEmail}
                onChange={handleInputChange}
              />
            </div>
            <p className="mt-1 text-xs text-gray-500">
              If left empty, replies will go to the sender email
            </p>
          </div>
          
          <div>
            <label htmlFor="emailVolume" className="block text-sm font-medium text-gray-700 mb-1">
              Expected Email Volume
            </label>
            <select
              id="emailVolume"
              name="emailVolume"
              className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.emailVolume}
              onChange={handleInputChange}
            >
              <option value="low">Low (up to 500 emails/month)</option>
              <option value="medium">Medium (500-5,000 emails/month)</option>
              <option value="high">High (5,000+ emails/month)</option>
            </select>
          </div>
          
          {/* Email Provider Selection */}
          <div className="mt-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
            <h4 className="text-sm font-medium text-gray-700 mb-3">Preferred Email Provider</h4>
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  id="providerDefault"
                  name="emailProvider"
                  type="radio"
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300"
                  value="default"
                  checked={formData.emailProvider === 'default'}
                  onChange={() => handleEmailProviderChange('default')}
                />
                <label htmlFor="providerDefault" className="ml-2 block text-sm text-gray-700">
                  Use default email service
                </label>
              </div>
              
              <div className="flex items-center">
                <input
                  id="providerGmail"
                  name="emailProvider"
                  type="radio"
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300"
                  value="gmail"
                  checked={formData.emailProvider === 'gmail'}
                  onChange={() => handleEmailProviderChange('gmail')}
                />
                <label htmlFor="providerGmail" className="ml-2 block text-sm text-gray-700">
                  Use Gmail / Google Workspace
                </label>
              </div>
              
              <div className="flex items-center">
                <input
                  id="providerSmtp"
                  name="emailProvider"
                  type="radio"
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300"
                  value="smtp"
                  checked={formData.emailProvider === 'smtp'}
                  onChange={() => handleEmailProviderChange('smtp')}
                />
                <label htmlFor="providerSmtp" className="ml-2 block text-sm text-gray-700">
                  Use custom SMTP server
                </label>
              </div>
            </div>
            <p className="mt-2 text-xs text-gray-500">
              You can configure provider details after creating the profile.
            </p>
          </div>
        </div>
        
        {/* Branding */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Branding</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Business Logo
              </label>
              <div className="mt-1 flex items-center">
                {formData.logoUrl ? (
                  <div className="relative">
                    <img
                      src={formData.logoUrl}
                      alt="Business Logo"
                      className="h-20 w-auto rounded border border-gray-300"
                    />
                    <button
                      type="button"
                      onClick={() => setFormData(prev => ({ ...prev, logo: null, logoUrl: '' }))}
                      className="absolute -top-2 -right-2 bg-red-100 rounded-full p-1 text-red-600 hover:bg-red-200"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ) : (
                  <label htmlFor="logo" className="cursor-pointer">
                    <div className="h-20 w-40 border-2 border-dashed border-gray-300 rounded flex flex-col items-center justify-center hover:border-[#190192]">
                      <Upload size={24} className="text-gray-400 mb-1" />
                      <span className="text-xs text-gray-500">Upload Logo</span>
                    </div>
                    <input
                      id="logo"
                      name="logo"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                )}
              </div>
              <p className="mt-1 text-xs text-gray-500">
                Recommended size: 400x400px. Max 5MB.
              </p>
            </div>
            
            <div>
              <div className="flex items-center mb-4">
                <input
                  id="hasCustomBranding"
                  name="hasCustomBranding"
                  type="checkbox"
                  className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
                  checked={formData.hasCustomBranding}
                  onChange={handleInputChange}
                />
                <label htmlFor="hasCustomBranding" className="ml-2 block text-sm text-gray-700">
                  Use custom branding colors
                </label>
              </div>
              
              {formData.hasCustomBranding && (
                <div className="space-y-3">
                  <div>
                    <label htmlFor="colors.primary" className="block text-sm font-medium text-gray-700 mb-1">
                      Primary Color
                    </label>
                    <div className="flex items-center">
                      <input
                        id="colors.primary"
                        name="colors.primary"
                        type="color"
                        className="h-8 w-8 rounded border border-gray-300 mr-2"
                        value={formData.brandColors.primary}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="colors.primary"
                        value={formData.brandColors.primary}
                        onChange={handleInputChange}
                        className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                      />
                    </div>
                  </div>
                  
                  <div>
                    <label htmlFor="colors.accent" className="block text-sm font-medium text-gray-700 mb-1">
                      Accent Color
                    </label>
                    <div className="flex items-center">
                      <input
                        id="colors.accent"
                        name="colors.accent"
                        type="color"
                        className="h-8 w-8 rounded border border-gray-300 mr-2"
                        value={formData.brandColors.accent}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="colors.accent"
                        value={formData.brandColors.accent}
                        onChange={handleInputChange}
                        className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Business Address */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Business Address</h3>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="md:col-span-2">
              <label htmlFor="address.street" className="block text-sm font-medium text-gray-700 mb-1">
                Street Address
              </label>
              <input
                id="address.street"
                name="address.street"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.businessAddress.street}
                onChange={handleInputChange}
              />
            </div>
            
            <div>
              <label htmlFor="address.city" className="block text-sm font-medium text-gray-700 mb-1">
                City
              </label>
              <input
                id="address.city"
                name="address.city"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.businessAddress.city}
                onChange={handleInputChange}
              />
            </div>
            
            <div>
              <label htmlFor="address.state" className="block text-sm font-medium text-gray-700 mb-1">
                State/Province
              </label>
              <input
                id="address.state"
                name="address.state"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.businessAddress.state}
                onChange={handleInputChange}
              />
            </div>
            
            <div>
              <label htmlFor="address.zipCode" className="block text-sm font-medium text-gray-700 mb-1">
                Postal/Zip Code
              </label>
              <input
                id="address.zipCode"
                name="address.zipCode"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.businessAddress.zipCode}
                onChange={handleInputChange}
              />
            </div>
            
            <div>
              <label htmlFor="address.country" className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <input
                id="address.country"
                name="address.country"
                type="text"
                className="block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
               value={formData.businessAddress.country}
               onChange={handleInputChange}
             />
           </div>
         </div>
       </div>
       
       {/* Legal and Compliance */}
       <div>
         <h3 className="text-lg font-medium text-gray-900 mb-4">Legal and Compliance</h3>
         
         <div className="space-y-4">
           <div className="flex items-start">
             <div className="flex items-center h-5">
               <input
                 id="marketingConsent"
                 name="marketingConsent"
                 type="checkbox"
                 className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
                 checked={formData.marketingConsent}
                 onChange={handleInputChange}
               />
             </div>
             <div className="ml-3 text-sm">
               <label htmlFor="marketingConsent" className="text-gray-700">
                 I agree to receive marketing communications
               </label>
               <p className="text-gray-500">
                 We'll send you updates about our services and industry news.
               </p>
             </div>
           </div>
           
           <div className="flex items-start">
             <div className="flex items-center h-5">
               <input
                 id="privacyPolicyAccepted"
                 name="privacyPolicyAccepted"
                 type="checkbox"
                 className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
                 checked={formData.privacyPolicyAccepted}
                 onChange={handleInputChange}
                 required
               />
             </div>
             <div className="ml-3 text-sm">
               <label htmlFor="privacyPolicyAccepted" className="text-gray-700">
                 I accept the <a href="/privacy-policy" className="text-[#190192] hover:underline">Privacy Policy</a> <span className="text-red-500">*</span>
               </label>
               <p className="text-gray-500">
                 By using our email services, you agree to comply with anti-spam regulations and email marketing best practices.
               </p>
             </div>
           </div>
         </div>
         
         <div className="mt-6 bg-blue-50 p-4 rounded-lg border border-blue-100">
           <div className="flex items-start">
             <Info className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
             <div>
               <h4 className="text-sm font-medium text-blue-700">Email Sending Information</h4>
               <p className="mt-1 text-sm text-blue-600">
                 After setting up your profile, you'll need to verify your sender email address before sending emails. 
                 You can do this from the Email Settings page after completing this form.
               </p>
             </div>
           </div>
         </div>
       </div>
       
       {/* Form Actions */}
       <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
         <button
           type="button"
           onClick={() => navigate('/communication/external')}
           className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
           disabled={saving}
         >
           Cancel
         </button>
         
         <button
           type="submit"
           disabled={saving}
           className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
             saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
           }`}
         >
           {saving ? (
             <>
               <Loader className="animate-spin h-4 w-4 mr-2" />
               Saving...
             </>
           ) : (
             <>
               <Save className="h-4 w-4 mr-2" />
               {id === 'new' ? 'Create Profile' : 'Save Changes'}
             </>
           )}
         </button>
       </div>
     </form>
   </div>
 );
};

export default BusinessEmailProfileForm;