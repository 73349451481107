// src/components/human-capital/team/TeamMemberDetail.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Download, Trash2, UserCircle, 
  Mail, Phone, Calendar, MapPin, Building, Briefcase, UserPlus,
  Users, FileText, PlusCircle, X, AlertCircle, Clock, Award
} from 'lucide-react';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useAuth } from '../../../context/AuthContext';

const TeamMemberDetail = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teamMember, setTeamMember] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch team member details
  useEffect(() => {
    const fetchTeamMemberDetails = async () => {
      if (!currentUser) return;
      
      setLoading(true);
      setError(null);
      
      try {
        const memberDoc = await getDoc(doc(db, 'team_members', memberId));
        
        if (memberDoc.exists()) {
          setTeamMember({
            id: memberId,
            ...memberDoc.data()
          });
        } else {
          setError('Team member not found');
        }
      } catch (error) {
        console.error('Error fetching team member details:', error);
        setError('Failed to load team member details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTeamMemberDetails();
  }, [memberId, currentUser]);

  // Handle delete
  const handleDelete = async () => {
    try {
      setLoading(true);
      
      // Delete the team member document
      await deleteDoc(doc(db, 'team_members', memberId));
      
      // Navigate back to the team list
      navigate('/human-capital/team');
    } catch (error) {
      console.error('Error deleting team member:', error);
      alert('Failed to delete team member. Please try again.');
      setLoading(false);
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric', 
        month: 'long', 
        day: 'numeric'
      });
    } catch (error) {
      return dateString;
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (error || !teamMember) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <AlertCircle size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Team member not found</h3>
          <p className="text-gray-500 mb-6">The team member you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/human-capital/team')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Team
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/human-capital/team"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Team
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg" title="Download profile as PDF">
              <Download size={20} />
            </button>
            <Link
              to={`/human-capital/team/edit/${memberId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Team Member Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="flex-shrink-0">
              {teamMember.photoUrl ? (
                <img 
                  src={teamMember.photoUrl} 
                  alt={`${teamMember.firstName} ${teamMember.lastName}`} 
                  className="h-24 w-24 rounded-full object-cover border-4 border-gray-200"
                />
              ) : (
                <div className="h-24 w-24 rounded-full bg-purple-100 flex items-center justify-center text-purple-800">
                  <UserCircle size={48} />
                </div>
              )}
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{teamMember.firstName} {teamMember.lastName}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  teamMember.status === 'active' ? 'bg-green-100 text-green-800' :
                  teamMember.status === 'on_leave' ? 'bg-amber-100 text-amber-800' :
                  teamMember.status === 'terminated' ? 'bg-red-100 text-red-800' :
                  'bg-gray-100 text-gray-800'
                }`}>
                  {teamMember.status === 'active' ? 'Active' :
                   teamMember.status === 'on_leave' ? 'On Leave' :
                   teamMember.status === 'terminated' ? 'Terminated' :
                   'Inactive'}
                </span>
              </div>
              
              <p className="text-gray-600">{teamMember.position}</p>
              <p className="text-gray-500">{teamMember.department}</p>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('documents')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'documents'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Documents
            </button>
            <button
              onClick={() => setActiveTab('leaves')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'leaves'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Leave
            </button>
            <button
              onClick={() => setActiveTab('performance')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'performance'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Performance
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Basic Info */}
              <div className="lg:col-span-2 space-y-8">
                {/* Contact Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Contact Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {teamMember.email && (
                      <div className="flex items-start">
                        <Mail size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Email</p>
                          <a href={`mailto:${teamMember.email}`} className="text-[#190192] hover:underline">
                            {teamMember.email}
                          </a>
                        </div>
                      </div>
                    )}
                    
                    {teamMember.phone && (
                      <div className="flex items-start">
                        <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Phone</p>
                          <p className="text-gray-900">{teamMember.phone}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {teamMember.address && (
                    <div className="mt-4 flex items-start">
                      <MapPin size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Address</p>
                        <p className="text-gray-900">{teamMember.address}</p>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Employment Information */}
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-4">Employment Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex items-start">
                      <Briefcase size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Position</p>
                        <p className="text-gray-900">{teamMember.position}</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <Building size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                      <div>
                        <p className="text-sm font-medium text-gray-500">Department</p>
                        <p className="text-gray-900">{teamMember.department}</p>
                      </div>
                    </div>
                    
                    {teamMember.hireDate && (
                      <div className="flex items-start">
                        <Calendar size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Hire Date</p>
                          <p className="text-gray-900">{formatDate(teamMember.hireDate)}</p>
                        </div>
                      </div>
                    )}
                    
                    {teamMember.employeeId && (
                      <div className="flex items-start">
                        <FileText size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-gray-500">Employee ID</p>
                          <p className="text-gray-900">{teamMember.employeeId}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Emergency Contact */}
                {teamMember.emergencyContact && (teamMember.emergencyContact.name || teamMember.emergencyContact.phone) && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Emergency Contact</h3>
                    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {teamMember.emergencyContact.name && (
                          <div className="flex items-start">
                            <Users size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                            <div>
                              <p className="text-sm font-medium text-gray-500">Name</p>
                              <p className="text-gray-900">{teamMember.emergencyContact.name}</p>
                              {teamMember.emergencyContact.relation && (
                                <p className="text-sm text-gray-500">{teamMember.emergencyContact.relation}</p>
                              )}
                            </div>
                          </div>
                        )}
                        
                        {teamMember.emergencyContact.phone && (
                          <div className="flex items-start">
                            <Phone size={18} className="text-gray-400 mt-0.5 mr-3 flex-shrink-0" />
                            <div>
                              <p className="text-sm font-medium text-gray-500">Phone</p>
                              <p className="text-gray-900">{teamMember.emergencyContact.phone}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                
                {/* Notes */}
                {teamMember.notes && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Notes</h3>
                    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                      <p className="text-gray-700">{teamMember.notes}</p>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Personal Information */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Personal Information</h3>
                  
                  <div className="space-y-4">
                    {teamMember.dateOfBirth && (
                      <div className="flex justify-between">
                        <span className="text-sm text-gray-500">Date of Birth</span>
                        <span className="text-sm font-medium text-gray-900">{formatDate(teamMember.dateOfBirth)}</span>
                      </div>
                    )}
                    
                    {teamMember.nationalId && (
                      <div className="flex justify-between">
                        <span className="text-sm text-gray-500">National ID</span>
                        <span className="text-sm font-medium text-gray-900">{teamMember.nationalId}</span>
                      </div>
                    )}
                  </div>
                </div>
                
                {/* Quick Actions */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Quick Actions</h3>
                  
                  <div className="space-y-2">
                    <Link
                      to={`/human-capital/onboarding/assign/${memberId}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <UserPlus size={16} className="mr-2" />
                      Assign Onboarding
                    </Link>
                    
                    <Link
                      to={`/human-capital/leaves/new?employeeId=${memberId}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Calendar size={16} className="mr-2" />
                      Record Leave
                    </Link>
                    
                    <Link
                      to={`/human-capital/performance/new?employeeId=${memberId}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <Award size={16} className="mr-2" />
                      Add Performance Review
                    </Link>
                    
                    <Link
                      to={`/human-capital/documents/upload?employeeId=${memberId}`}
                      className="block w-full text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 flex items-center justify-center"
                    >
                      <FileText size={16} className="mr-2" />
                      Upload Document
                    </Link>
                  </div>
                </div>
                
                {/* Meta Information */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Information</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Created</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {teamMember.createdAt ? formatDate(teamMember.createdAt.toDate()) : 'N/A'}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Last Updated</span>
                      <span className="text-sm text-gray-900 flex items-center">
                        <Clock size={14} className="mr-1.5" />
                        {teamMember.updatedAt ? formatDate(teamMember.updatedAt.toDate()) : 'N/A'}
                      </span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">ID</span>
                      <span className="text-sm text-gray-900">{teamMember.id}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {activeTab === 'documents' && (
            <div>
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-medium text-gray-900">Documents</h3>
                <Link
                  to={`/human-capital/documents/upload?employeeId=${memberId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Upload Document
                </Link>
              </div>
              
              {/* This would be populated with document list from Firestore */}
              <div className="bg-gray-50 rounded-xl py-12 px-6 text-center">
                <FileText size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No documents yet</h3>
                <p className="text-gray-500 mb-6 max-w-md mx-auto">
                  There are no documents uploaded for this team member yet.
                </p>
                <Link
                  to={`/human-capital/documents/upload?employeeId=${memberId}`}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Upload First Document
                </Link>
              </div>
            </div>
          )}
          
          {activeTab === 'leaves' && (
            <div>
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-medium text-gray-900">Leave Records</h3>
                <Link
                  to={`/human-capital/leaves/new?employeeId=${memberId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Record Leave
                </Link>
              </div>
              
              {/* This would be populated with leave records from Firestore */}
              <div className="bg-gray-50 rounded-xl py-12 px-6 text-center">
                <Calendar size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No leave records</h3>
                <p className="text-gray-500 mb-6 max-w-md mx-auto">
                  There are no leave records for this team member yet.
                </p>
                <Link
                  to={`/human-capital/leaves/new?employeeId=${memberId}`}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Record First Leave
                </Link>
              </div>
            </div>
          )}
          
          {activeTab === 'performance' && (
            <div>
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-medium text-gray-900">Performance Reviews</h3>
                <Link
                  to={`/human-capital/performance/new?employeeId=${memberId}`}
                  className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Add Review
                </Link>
              </div>
              
              {/* This would be populated with performance reviews from Firestore */}
              <div className="bg-gray-50 rounded-xl py-12 px-6 text-center">
                <Award size={48} className="mx-auto text-gray-400 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">No performance reviews</h3>
                <p className="text-gray-500 mb-6 max-w-md mx-auto">
                  There are no performance reviews for this team member yet.
                </p>
                <Link
                  to={`/human-capital/performance/new?employeeId=${memberId}`}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                >
                  <PlusCircle size={16} className="mr-2" />
                  Add First Review
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Team Member</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete <span className="font-medium">{teamMember.firstName} {teamMember.lastName}</span>? This action cannot be undone and will delete all associated documents and records.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamMemberDetail;