// src/components/crm/communication/IntegrationList.jsx
import React, { useState, useEffect } from 'react';
import { 
  Mail, MessageSquare, Phone, Calendar, ExternalLink, 
  ToggleLeft, ToggleRight, Settings, Plus, RefreshCw,
  AlertTriangle, CheckCircle, Filter, Search, Zap
} from 'lucide-react';

const IntegrationList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [integrations, setIntegrations] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  
  // Fetch integrations
  useEffect(() => {
    const fetchIntegrations = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Mock integration data
        const mockIntegrations = [
          {
            id: '1',
            name: 'Email Integration',
            type: 'email',
            provider: 'Gmail',
            status: 'active',
            lastSync: '2025-03-20T15:30:00Z',
            contactsSync: true,
            messagesSync: true,
            autoCapture: true,
            icon: Mail
          },
          {
            id: '2',
            name: 'Messaging Platform',
            type: 'chat',
            provider: 'Slack',
            status: 'active',
            lastSync: '2025-03-21T10:45:00Z',
            contactsSync: true,
            messagesSync: true,
            autoCapture: true,
            icon: MessageSquare
          },
          {
            id: '3',
            name: 'Phone System',
            type: 'phone',
            provider: 'VoIP Provider',
            status: 'inactive',
            lastSync: '2025-03-15T09:15:00Z',
            contactsSync: false,
            messagesSync: false,
            autoCapture: false,
            icon: Phone
          },
          {
            id: '4',
            name: 'Calendar Integration',
            type: 'calendar',
            provider: 'Google Calendar',
            status: 'active',
            lastSync: '2025-03-21T08:30:00Z',
            contactsSync: true,
            messagesSync: false,
            autoCapture: true,
            icon: Calendar
          },
          {
            id: '5',
            name: 'Social Media',
            type: 'social',
            provider: 'LinkedIn',
            status: 'pending',
            lastSync: null,
            contactsSync: false,
            messagesSync: false,
            autoCapture: false,
            icon: ExternalLink
          }
        ];
        
        setIntegrations(mockIntegrations);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching integrations:', error);
        setIsLoading(false);
      }
    };
    
    fetchIntegrations();
  }, []);
  
  // Filter integrations based on search and filter
  const getFilteredIntegrations = () => {
    return integrations.filter(integration => {
      // Filter by status
      if (filterStatus !== 'all' && integration.status !== filterStatus) {
        return false;
      }
      
      // Filter by search term
      if (searchTerm && !integration.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
          !integration.provider.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
      
      return true;
    });
  };
  
  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };
  
  // Toggle integration status
  const toggleIntegrationStatus = (id) => {
    setIntegrations(prevIntegrations => 
      prevIntegrations.map(integration => 
        integration.id === id 
          ? { 
              ...integration, 
              status: integration.status === 'active' ? 'inactive' : 'active'
            }
          : integration
      )
    );
  };
  
  // Sync integration
  const syncIntegration = async (id) => {
    // Find the integration
    const integration = integrations.find(i => i.id === id);
    if (!integration || integration.status !== 'active') return;
    
    // Set temporary syncing status
    setIntegrations(prevIntegrations => 
      prevIntegrations.map(integration => 
        integration.id === id 
          ? { ...integration, isSyncing: true }
          : integration
      )
    );
    
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1500));
      
      // Update integration with new sync time
      setIntegrations(prevIntegrations => 
        prevIntegrations.map(integration => 
          integration.id === id 
            ? { 
                ...integration, 
                lastSync: new Date().toISOString(),
                isSyncing: false
              }
            : integration
        )
      );
    } catch (error) {
      console.error('Error syncing integration:', error);
      
      // Reset syncing status on error
      setIntegrations(prevIntegrations => 
        prevIntegrations.map(integration => 
          integration.id === id 
            ? { ...integration, isSyncing: false }
            : integration
        )
      );
    }
  };
  
  return (
    <div className="space-y-6">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search integrations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
          </select>
          
          <button
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
            onClick={() => {
              // Open modal or navigate to add integration page
              alert('Add Integration functionality would open here');
            }}
          >
            <Plus size={18} className="mr-2" />
            Add Integration
          </button>
        </div>
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Integration</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provider</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Sync</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Auto-Capture</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getFilteredIntegrations().map((integration) => {
                  const IntegrationIcon = integration.icon;
                  
                  return (
                    <tr key={integration.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-10 w-10 rounded-full bg-[#190192] bg-opacity-10 flex items-center justify-center">
                            <IntegrationIcon size={20} className="text-[#190192]" />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{integration.name}</div>
                            <div className="text-xs text-gray-500">{integration.type}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {integration.provider}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          integration.status === 'active' 
                            ? 'bg-green-100 text-green-800' 
                            : integration.status === 'pending'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-gray-100 text-gray-800'
                        }`}>
                          {integration.status.charAt(0).toUpperCase() + integration.status.slice(1)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {integration.isSyncing ? (
                          <div className="flex items-center">
                            <RefreshCw size={14} className="mr-1.5 animate-spin text-[#190192]" />
                            <span>Syncing...</span>
                          </div>
                        ) : (
                          formatDate(integration.lastSync)
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <button
                          className="inline-flex"
                          onClick={() => toggleIntegrationStatus(integration.id)}
                          disabled={integration.status === 'pending'}
                        >
                          {integration.status === 'active' ? (
                            <ToggleRight size={24} className="text-green-500" />
                          ) : (
                            <ToggleLeft size={24} className="text-gray-400" />
                          )}
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex items-center justify-end space-x-2">
                          <button
                            className="p-1.5 text-gray-500 hover:text-[#190192] rounded-md hover:bg-gray-100"
                            disabled={integration.status !== 'active' || integration.isSyncing}
                            onClick={() => syncIntegration(integration.id)}
                          >
                            <RefreshCw size={18} />
                          </button>
                          <button 
                            className="p-1.5 text-gray-500 hover:text-[#190192] rounded-md hover:bg-gray-100"
                            onClick={() => {
                              // Navigate to settings page for this integration
                              alert(`Configure ${integration.name}`);
                            }}
                          >
                            <Settings size={18} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          
          {getFilteredIntegrations().length === 0 && (
            <div className="text-center py-16">
              <Zap size={48} className="mx-auto text-gray-300 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No integrations found</h3>
              <p className="text-gray-500 max-w-md mx-auto">
                {searchTerm || filterStatus !== 'all' 
                  ? "No integrations match your search criteria." 
                  : "You haven't added any communication integrations yet. Integrations help track customer interactions automatically."}
              </p>
              {(searchTerm || filterStatus !== 'all') && (
                <button
                  className="mt-4 px-4 py-2 text-sm text-gray-600 border border-gray-300 rounded-md hover:bg-gray-50"
                  onClick={() => {
                    setSearchTerm('');
                    setFilterStatus('all');
                  }}
                >
                  Clear Filters
                </button>
              )}
            </div>
          )}
        </div>
      )}
      
      {/* Info Section */}
      <div className="bg-blue-50 p-4 rounded-lg border border-blue-100 flex items-start">
        <div className="flex-shrink-0 mr-3 mt-0.5">
          <Info size={18} className="text-blue-500" />
        </div>
        <div>
          <h4 className="text-sm font-medium text-blue-800 mb-1">What are Communication Integrations?</h4>
          <p className="text-sm text-blue-600">
            Communication integrations connect your CRM to external communication channels like email, phone systems, messaging apps, and calendars. 
            This allows the system to automatically track customer interactions, sync contacts, and capture communication history to maintain a complete 
            record of your customer relationships.
          </p>
        </div>
      </div>
    </div>
  );
};

function Info({ size, className }) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={size} 
      height={size} 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      className={className}
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="12" y1="16" x2="12" y2="12" />
      <line x1="12" y1="8" x2="12.01" y2="8" />
    </svg>
  );
}

export default IntegrationList;