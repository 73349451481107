// src/components/human-capital/recruitment/JobForm.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { 
  ChevronLeft, Save, AlertCircle, Briefcase, MapPin, 
  Calendar, Clock, Tag, Building, DollarSign
} from 'lucide-react';
import { doc, getDoc, setDoc, updateDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useAuth } from '../../../context/AuthContext';
import { AppContext } from '../../../context/AppContext';

const JobForm = ({ isEditing = false }) => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { activeBusiness } = useContext(AppContext);

  const [loading, setLoading] = useState(isEditing);
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // Form state
  const [formData, setFormData] = useState({
    title: '',
    department: '',
    location: '',
    type: 'Full-time',
    salaryMin: '',
    salaryMax: '',
    salaryCurrency: 'USD',
    salaryPeriod: 'yearly',
    description: '',
    requirements: '',
    benefits: '',
    closingDate: '',
    status: 'draft',
    isRemote: false,
    isUrgent: false,
    applyUrl: '',
    contactEmail: ''
  });

  // Job types
  // Job types
  const jobTypes = ['Full-time', 'Part-time', 'Contract', 'Temporary', 'Internship', 'Volunteer'];

  // Departments
  const departments = [
    'Administration', 'Engineering', 'Finance', 'Human Resources', 
    'Information Technology', 'Marketing', 'Operations', 'Product',
    'Research & Development', 'Sales', 'Customer Support'
  ];

  // Fetch job data if editing
  useEffect(() => {
    if (isEditing && jobId) {
      const fetchJobData = async () => {
        setLoading(true);
        try {
          const jobDoc = await getDoc(doc(db, 'job_postings', jobId));
          
          if (jobDoc.exists()) {
            setFormData({
              ...jobDoc.data(),
              closingDate: jobDoc.data().closingDate ? new Date(jobDoc.data().closingDate).toISOString().split('T')[0] : ''
            });
          } else {
            setFormErrors({ general: 'Job posting not found. Please try again.' });
          }
        } catch (error) {
          console.error('Error fetching job posting:', error);
          setFormErrors({ general: 'Failed to load job posting data. Please try again.' });
        } finally {
          setLoading(false);
        }
      };
      
      fetchJobData();
    }
  }, [isEditing, jobId]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    // Clear error for this field
    if (formErrors[name]) {
      setFormErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = {};
    if (!formData.title) errors.title = 'Job title is required';
    if (!formData.department) errors.department = 'Department is required';
    if (!formData.description) errors.description = 'Job description is required';
    
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    
    if (!activeBusiness?.id) {
      setFormErrors({ general: 'No active business selected. Please select a business first.' });
      return;
    }
    
    setSaving(true);
    
    try {
      // Prepare data
      const jobData = {
        ...formData,
        businessId: activeBusiness.id,
        updatedAt: serverTimestamp()
      };
      
      // Add numeric values for salary
      if (formData.salaryMin) jobData.salaryMin = Number(formData.salaryMin);
      if (formData.salaryMax) jobData.salaryMax = Number(formData.salaryMax);
      
      if (isEditing) {
        // Update existing job
        await updateDoc(doc(db, 'job_postings', jobId), jobData);
      } else {
        // Create new job
        const newJobRef = doc(collection(db, 'job_postings'));
        jobData.createdAt = serverTimestamp();
        jobData.applicants = 0;
        await setDoc(newJobRef, jobData);
      }
      
      // Navigate back to jobs list
      navigate('/human-capital/recruitment');
    } catch (error) {
      console.error('Error saving job posting:', error);
      setFormErrors({ general: 'Failed to save job posting. Please try again.' });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center">
          <button 
            type="button"
            onClick={() => navigate('/human-capital/recruitment')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center mr-4"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit Job Posting' : 'Create New Job Posting'}
          </h2>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        {/* Form Error Message */}
        {formErrors.general && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
            <div className="flex">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{formErrors.general}</p>
            </div>
          </div>
        )}
        
        {/* Basic Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Job Title */}
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              Job Title <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Briefcase size={18} className="text-gray-400" />
              </div>
              <input
                id="title"
                name="title"
                type="text"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.title ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.title}
                onChange={handleInputChange}
                placeholder="e.g. Senior Software Engineer"
              />
            </div>
            {formErrors.title && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.title}
              </p>
            )}
          </div>
          
          {/* Department */}
          <div>
            <label htmlFor="department" className="block text-sm font-medium text-gray-700 mb-1">
              Department <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Building size={18} className="text-gray-400" />
              </div>
              <select
                id="department"
                name="department"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.department ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.department}
                onChange={handleInputChange}
              >
                <option value="">Select Department</option>
                {departments.map(dept => (
                  <option key={dept} value={dept}>{dept}</option>
                ))}
              </select>
            </div>
            {formErrors.department && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.department}
              </p>
            )}
          </div>
          
          {/* Location */}
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">
              Location
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin size={18} className="text-gray-400" />
              </div>
              <input
                id="location"
                name="location"
                type="text"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.location}
                onChange={handleInputChange}
                placeholder="e.g. San Francisco, CA"
              />
            </div>
          </div>
          
          {/* Job Type */}
          <div>
            <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
              Job Type
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Tag size={18} className="text-gray-400" />
              </div>
              <select
                id="type"
                name="type"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.type}
                onChange={handleInputChange}
              >
                {jobTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
          </div>
          
          {/* Closing Date */}
          <div>
            <label htmlFor="closingDate" className="block text-sm font-medium text-gray-700 mb-1">
              Closing Date
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar size={18} className="text-gray-400" />
              </div>
              <input
                id="closingDate"
                name="closingDate"
                type="date"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.closingDate}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          {/* Status */}
          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <select
              id="status"
              name="status"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value="draft">Draft</option>
              <option value="open">Open</option>
              <option value="closed">Closed</option>
            </select>
          </div>
        </div>
        
        {/* Options */}
        <div className="mb-6 flex items-center space-x-6">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isRemote"
              className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              checked={formData.isRemote}
              onChange={handleInputChange}
            />
            <span className="ml-2 text-sm text-gray-700">Remote Position</span>
          </label>
          
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name="isUrgent"
              className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              checked={formData.isUrgent}
              onChange={handleInputChange}
            />
            <span className="ml-2 text-sm text-gray-700">Urgent Hiring</span>
          </label>
        </div>
        
        {/* Salary Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Salary Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
          {/* Salary Min */}
          <div>
            <label htmlFor="salaryMin" className="block text-sm font-medium text-gray-700 mb-1">
              Minimum Salary
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign size={18} className="text-gray-400" />
              </div>
              <input
                id="salaryMin"
                name="salaryMin"
                type="number"
                min="0"
                step="1000"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.salaryMin}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          {/* Salary Max */}
          <div>
            <label htmlFor="salaryMax" className="block text-sm font-medium text-gray-700 mb-1">
              Maximum Salary
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <DollarSign size={18} className="text-gray-400" />
              </div>
              <input
                id="salaryMax"
                name="salaryMax"
                type="number"
                min="0"
                step="1000"
                className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                value={formData.salaryMax}
                onChange={handleInputChange}
              />
            </div>
          </div>
          
          {/* Salary Currency */}
          <div>
            <label htmlFor="salaryCurrency" className="block text-sm font-medium text-gray-700 mb-1">
              Currency
            </label>
            <select
              id="salaryCurrency"
              name="salaryCurrency"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.salaryCurrency}
              onChange={handleInputChange}
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="CAD">CAD</option>
              <option value="AUD">AUD</option>
              <option value="ZAR">ZAR</option>
            </select>
          </div>
          
          {/* Salary Period */}
          <div>
            <label htmlFor="salaryPeriod" className="block text-sm font-medium text-gray-700 mb-1">
              Period
            </label>
            <select
              id="salaryPeriod"
              name="salaryPeriod"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.salaryPeriod}
              onChange={handleInputChange}
            >
              <option value="yearly">Yearly</option>
              <option value="monthly">Monthly</option>
              <option value="hourly">Hourly</option>
              <option value="project">Per Project</option>
            </select>
          </div>
        </div>
        
        {/* Job Description */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Job Details</h3>
        
        <div className="space-y-6 mb-6">
          {/* Description */}
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Job Description <span className="text-red-500">*</span>
            </label>
            <textarea
              id="description"
              name="description"
              rows="6"
              className={`block w-full rounded-lg border ${
                formErrors.description ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Provide a detailed description of the job..."
            ></textarea>
            {formErrors.description && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.description}
              </p>
            )}
          </div>
          
          {/* Requirements */}
          <div>
            <label htmlFor="requirements" className="block text-sm font-medium text-gray-700 mb-1">
              Requirements & Qualifications
            </label>
            <textarea
              id="requirements"
              name="requirements"
              rows="4"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.requirements}
              onChange={handleInputChange}
              placeholder="List required skills, education, and experience..."
            ></textarea>
          </div>
          
          {/* Benefits */}
          <div>
            <label htmlFor="benefits" className="block text-sm font-medium text-gray-700 mb-1">
              Benefits & Perks
            </label>
            <textarea
              id="benefits"
              name="benefits"
              rows="4"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.benefits}
              onChange={handleInputChange}
              placeholder="List benefits, perks, and other advantages..."
            ></textarea>
          </div>
        </div>
        
        {/* Application Details */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Application Details</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Application URL */}
          <div>
            <label htmlFor="applyUrl" className="block text-sm font-medium text-gray-700 mb-1">
              Application URL
            </label>
            <input
              id="applyUrl"
              name="applyUrl"
              type="url"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.applyUrl}
              onChange={handleInputChange}
              placeholder="https://..."
            />
            <p className="mt-1 text-xs text-gray-500">
              External application link (leave empty to use internal system)
            </p>
          </div>
          
          {/* Contact Email */}
          <div>
            <label htmlFor="contactEmail" className="block text-sm font-medium text-gray-700 mb-1">
              Contact Email
            </label>
            <input
              id="contactEmail"
              name="contactEmail"
              type="email"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.contactEmail}
              onChange={handleInputChange}
              placeholder="careers@yourbusiness.com"
            />
            <p className="mt-1 text-xs text-gray-500">
              Email for inquiries about this position
            </p>
          </div>
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/human-capital/recruitment')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={saving}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={saving}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {saving ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                {isEditing ? 'Update Job Posting' : 'Create Job Posting'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobForm;