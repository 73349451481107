// src/components/crm/communication/InteractionForm.jsx
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ChevronLeft, Save, AlertCircle, User, 
  Mail, Phone, Calendar, MessageSquare, Clock,
  Building, Upload, X, Tag, CheckCircle, Paperclip
} from 'lucide-react';

const InteractionForm = ({ isEditing = false }) => {
  const { interactionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  // Get contact ID and type from URL parameters if creating from contact
  const initialContactId = queryParams.get('contactId');
  const initialType = queryParams.get('type') || 'email';
  
  const [loading, setLoading] = useState(isEditing);
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  
  const [formData, setFormData] = useState({
    type: initialType,
    direction: 'outbound',
    subject: '',
    content: '',
    summary: '',
    date: new Date().toISOString().split('T')[0], // Default to today
    time: new Date().toTimeString().slice(0, 5), // Default to current time
    duration: '',
    contactId: initialContactId || '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    company: '',
    assignedTo: '',
    relatedType: '',
    relatedId: '',
    relatedName: '',
    tags: [],
    attachments: [],
    nextSteps: '',
    followUpDate: '',
    followUpTime: ''
  });
  
  // Interaction types
  const interactionTypes = [
    { id: 'email', label: 'Email', icon: Mail },
    { id: 'call', label: 'Call', icon: Phone },
    { id: 'meeting', label: 'Meeting', icon: Calendar },
    { id: 'message', label: 'Message', icon: MessageSquare }
  ];
  
  // Direction options
  const directionOptions = [
    { id: 'inbound', label: 'Inbound' },
    { id: 'outbound', label: 'Outbound' }
  ];
  
  // Staff members (for assignment)
  const [staffMembers, setStaffMembers] = useState([
    { id: '1', name: 'Michael Johnson' },
    { id: '2', name: 'Sarah Kim' },
    { id: '3', name: 'David Chen' }
  ]);
  
  // Related Entity Types
  const relatedEntityTypes = [
    { id: 'lead', label: 'Lead' },
    { id: 'opportunity', label: 'Opportunity' },
    { id: 'company', label: 'Company' },
    { id: 'ticket', label: 'Ticket' }
  ];
  
  // Contacts list (would come from API)
  const [contacts, setContacts] = useState([
    { id: '123', name: 'John Smith', email: 'john@example.com', phone: '+1 (555) 123-4567', company: 'Acme Corporation' },
    { id: '124', name: 'Jane Doe', email: 'jane@example.com', phone: '+1 (555) 987-6543', company: 'Beta Industries' },
    { id: '125', name: 'Robert Chen', email: 'robert@example.com', phone: '+1 (555) 456-7890', company: 'Gamma Technologies' }
  ]);
  
  // Tag suggestions
  const tagSuggestions = [
    'Follow Up', 'Information Request', 'New Lead', 'Complaint', 
    'Feedback', 'Technical Support', 'Billing', 'Demo'
  ];
  
  // Tag input state
  const [tagInput, setTagInput] = useState('');
  
  // Fetch interaction data if editing
  useEffect(() => {
    if (isEditing && interactionId) {
      const fetchInteractionData = async () => {
        setLoading(true);
        try {
          // In a real app, this would be an API call
          await new Promise(resolve => setTimeout(resolve, 800));
          
          // Mock interaction data
          const mockInteraction = {
            id: interactionId,
            type: 'email',
            direction: 'inbound',
            subject: 'Product Information Request',
            content: `Hello,\n\nI'm interested in learning more about your software solutions for task management. Could you please send me information about your pricing plans and key features?\n\nAlso, do you offer any integrations with calendar applications?\n\nThank you,\nJohn Smith`,
            summary: 'Customer inquired about new product features and pricing options.',
            date: '2025-03-20',
            time: '14:30',
            contactId: '123',
            contactName: 'John Smith',
            contactEmail: 'john@example.com',
            contactPhone: '+1 (555) 123-4567',
            company: 'Acme Corporation',
            assignedTo: '2', // Sarah Kim's ID
            relatedType: 'company',
            relatedId: '456',
            relatedName: 'Acme Corporation',
            tags: ['Information Request', 'Follow Up', 'New Lead'],
            attachments: [
              { id: '1', name: 'Product_Brochure.pdf', type: 'application/pdf', size: '1.2MB' }
            ],
            nextSteps: 'Send pricing information and schedule a demo call.',
            followUpDate: '2025-03-25',
            followUpTime: '10:00'
          };
          
          setFormData({
            ...mockInteraction,
            duration: mockInteraction.type === 'call' ? '10' : ''
          });
        } catch (error) {
          console.error('Error fetching interaction:', error);
        } finally {
          setLoading(false);
        }
      };
      
      fetchInteractionData();
    } else if (initialContactId) {
      // If creating from contact, populate contact information
      const contact = contacts.find(c => c.id === initialContactId);
      if (contact) {
        setFormData(prev => ({
          ...prev,
          contactId: contact.id,
          contactName: contact.name,
          contactEmail: contact.email,
          contactPhone: contact.phone,
          company: contact.company
        }));
      }
    }
  }, [isEditing, interactionId, initialContactId, contacts]);
  
  // Update title based on selected type
  useEffect(() => {
    document.title = `${isEditing ? 'Edit' : 'Log'} ${formData.type.charAt(0).toUpperCase() + formData.type.slice(1)} | CRM`;
    
    // Reset duration when type changes
    if (formData.type !== 'call' && formData.duration) {
      setFormData(prev => ({ ...prev, duration: '' }));
    }
  }, [formData.type, isEditing]);
  
  // Handle contact change
  const handleContactChange = (e) => {
    const contactId = e.target.value;
    if (!contactId) {
      setFormData(prev => ({
        ...prev,
        contactId: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        company: ''
      }));
      return;
    }
    
    const contact = contacts.find(c => c.id === contactId);
    if (contact) {
      setFormData(prev => ({
        ...prev,
        contactId: contact.id,
        contactName: contact.name,
        contactEmail: contact.email,
        contactPhone: contact.phone,
        company: contact.company
      }));
    }
  };
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkboxes
    if (type === 'checkbox') {
      setFormData(prev => ({
        ...prev,
        [name]: checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Handle type change
  const handleTypeChange = (type) => {
    setFormData(prev => ({
      ...prev,
      type
    }));
    
    if (formErrors.type) {
      setFormErrors(prev => ({
        ...prev,
        type: ''
      }));
    }
  };
  
  // Handle direction change
  const handleDirectionChange = (direction) => {
    setFormData(prev => ({
      ...prev,
      direction
    }));
    
    if (formErrors.direction) {
      setFormErrors(prev => ({
        ...prev,
        direction: ''
      }));
    }
  };
  
  // Handle tag input
  const handleTagKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput.trim()) {
      e.preventDefault();
      
      // Add tag if it doesn't already exist
      if (!formData.tags.includes(tagInput.trim())) {
        setFormData(prev => ({
          ...prev,
          tags: [...prev.tags, tagInput.trim()]
        }));
      }
      
      setTagInput('');
    }
  };
  
  // Add tag from suggestions
  const handleAddTag = (tag) => {
    if (!formData.tags.includes(tag)) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, tag]
      }));
    }
  };
  
  // Remove tag
  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };
  
  // Handle file upload
  const handleFileUpload = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const newAttachments = Array.from(files).map(file => ({
      id: Date.now().toString() + Math.random().toString(36).substring(2),
      name: file.name,
      type: file.type,
      size: `${Math.round(file.size / 1024)}KB`
    }));
    
    setFormData(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...newAttachments]
    }));
    
    // Reset input
    e.target.value = '';
  };
  
  // Handle remove attachment
  const handleRemoveAttachment = (id) => {
    setFormData(prev => ({
      ...prev,
      attachments: prev.attachments.filter(att => att.id !== id)
    }));
  };
  
  // Validate form
  const validateForm = () => {
    const errors = {};
    
    if (!formData.type) {
      errors.type = 'Interaction type is required';
    }
    
    if (formData.type === 'email' && !formData.subject.trim()) {
      errors.subject = 'Subject is required for emails';
    }
    
    if (!formData.contactId) {
      errors.contactId = 'Contact is required';
    }
    
    if (!formData.date) {
      errors.date = 'Date is required';
    }
    
    if (!formData.time) {
      errors.time = 'Time is required';
    }
    
    if (!formData.content.trim()) {
      errors.content = 'Content is required';
    }
    
    if (formData.type === 'call' && !formData.duration) {
      errors.duration = 'Duration is required for calls';
    }
    
    if (!formData.summary.trim()) {
      errors.summary = 'Summary is required';
    }
    
    // If follow-up date is set, time should also be set
    if (formData.followUpDate && !formData.followUpTime) {
      errors.followUpTime = 'Follow-up time is required';
    }
    
    return errors;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      
      // Scroll to first error
      const firstErrorElement = document.querySelector('.text-red-600');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
      return;
    }
    
    setSaving(true);
    try {
      // In a real app, this would be an API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Navigate back to the interactions list or detail page
      navigate(isEditing ? `/crm/interactions/${interactionId}` : '/crm/interactions');
    } catch (error) {
      console.error('Error saving interaction:', error);
      setFormErrors({ submit: 'An error occurred while saving. Please try again.' });
    } finally {
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-xl shadow-md overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center">
          <button 
            type="button"
            onClick={() => navigate('/crm/interactions')}
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center mr-4"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back
          </button>
          <h2 className="text-lg font-medium text-gray-900">
            {isEditing ? 'Edit' : 'Log'} {formData.type.charAt(0).toUpperCase() + formData.type.slice(1)}
          </h2>
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="p-6">
        {/* Form Error Message */}
        {formErrors.submit && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
            <div className="flex">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{formErrors.submit}</p>
            </div>
          </div>
        )}
        
        {/* Interaction Type Selection */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Interaction Type <span className="text-red-500">*</span>
          </label>
          <div className="flex flex-wrap gap-2">
            {interactionTypes.map((type) => {
              const TypeIcon = type.icon;
              return (
                <button
                  key={type.id}
                  type="button"
                  onClick={() => handleTypeChange(type.id)}
                  className={`px-4 py-2 rounded-lg border flex items-center ${
                    formData.type === type.id 
                      ? 'bg-[#190192] text-white border-[#190192]' 
                      : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                  }`}
                >
                  <TypeIcon size={18} className="mr-2" /> 
                  {type.label}
                </button>
              );
            })}
          </div>
          {formErrors.type && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.type}
            </p>
          )}
        </div>
        
        {/* Direction (Inbound/Outbound) */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Direction
          </label>
          <div className="flex gap-3">
            {directionOptions.map((option) => (
              <button
                key={option.id}
                type="button"
                onClick={() => handleDirectionChange(option.id)}
                className={`px-4 py-2 rounded-lg border ${
                  formData.direction === option.id 
                    ? 'bg-[#190192] text-white border-[#190192]'
                    : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50'
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        
        {/* Contact Selection */}
        <div className="mb-6">
          <label htmlFor="contactId" className="block text-sm font-medium text-gray-700 mb-1">
            Contact <span className="text-red-500">*</span>
          </label>
          <select
            id="contactId"
            name="contactId"
            className={`block w-full rounded-lg border ${
              formErrors.contactId ? 'border-red-300' : 'border-gray-300'
            } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.contactId}
            onChange={handleContactChange}
          >
            <option value="">Select Contact</option>
            {contacts.map((contact) => (
              <option key={contact.id} value={contact.id}>
                {contact.name} ({contact.company})
              </option>
            ))}
          </select>
          {formErrors.contactId && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.contactId}
            </p>
          )}
          
          {formData.contactId && (
            <div className="mt-2 p-3 bg-gray-50 rounded-lg border border-gray-200">
              <div className="flex items-center mb-2">
                <User size={16} className="text-gray-500 mr-2" />
                <span className="text-sm font-medium">{formData.contactName}</span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-xs text-gray-500">
                {formData.contactEmail && (
                  <div className="flex items-center">
                    <Mail size={12} className="mr-1" /> {formData.contactEmail}
                  </div>
                )}
                {formData.contactPhone && (
                  <div className="flex items-center">
                    <Phone size={12} className="mr-1" /> {formData.contactPhone}
                  </div>
                )}
                {formData.company && (
                  <div className="flex items-center">
                    <Building size={12} className="mr-1" /> {formData.company}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        
        {/* Date & Time */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
              Date <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar size={18} className="text-gray-400" />
              </div>
              <input
                id="date"
                name="date"
                type="date"
                className={`pl-10 block w-full rounded-lg border ${
                    formErrors.date ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.date}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.date && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.date}
              </p>
            )}
          </div>
          
          <div>
            <label htmlFor="time" className="block text-sm font-medium text-gray-700 mb-1">
              Time <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Clock size={18} className="text-gray-400" />
              </div>
              <input
                id="time"
                name="time"
                type="time"
                className={`pl-10 block w-full rounded-lg border ${
                  formErrors.time ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.time}
                onChange={handleInputChange}
              />
            </div>
            {formErrors.time && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.time}
              </p>
            )}
          </div>
          
          {formData.type === 'call' && (
            <div>
              <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">
                Duration (minutes) <span className="text-red-500">*</span>
              </label>
              <input
                id="duration"
                name="duration"
                type="number"
                min="1"
                className={`block w-full rounded-lg border ${
                  formErrors.duration ? 'border-red-300' : 'border-gray-300'
                } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                value={formData.duration}
                onChange={handleInputChange}
                placeholder="10"
              />
              {formErrors.duration && (
                <p className="mt-1 text-red-600 text-xs flex items-center">
                  <AlertCircle size={12} className="mr-1" /> {formErrors.duration}
                </p>
              )}
            </div>
          )}
        </div>
        
        {/* Subject (only for email and message) */}
        {(formData.type === 'email' || formData.type === 'message') && (
          <div className="mb-6">
            <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
              Subject {formData.type === 'email' && <span className="text-red-500">*</span>}
            </label>
            <input
              id="subject"
              name="subject"
              type="text"
              className={`block w-full rounded-lg border ${
                formErrors.subject ? 'border-red-300' : 'border-gray-300'
              } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
              value={formData.subject}
              onChange={handleInputChange}
              placeholder="Enter subject"
            />
            {formErrors.subject && (
              <p className="mt-1 text-red-600 text-xs flex items-center">
                <AlertCircle size={12} className="mr-1" /> {formErrors.subject}
              </p>
            )}
          </div>
        )}
        
        {/* Content */}
        <div className="mb-6">
          <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">
            Content <span className="text-red-500">*</span>
          </label>
          <textarea
            id="content"
            name="content"
            rows="5"
            className={`block w-full rounded-lg border ${
              formErrors.content ? 'border-red-300' : 'border-gray-300'
            } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.content}
            onChange={handleInputChange}
            placeholder="Detailed content of the interaction..."
          />
          {formErrors.content && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.content}
            </p>
          )}
        </div>
        
        {/* Summary */}
        <div className="mb-6">
          <label htmlFor="summary" className="block text-sm font-medium text-gray-700 mb-1">
            Summary <span className="text-red-500">*</span>
          </label>
          <input
            id="summary"
            name="summary"
            type="text"
            className={`block w-full rounded-lg border ${
              formErrors.summary ? 'border-red-300' : 'border-gray-300'
            } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
            value={formData.summary}
            onChange={handleInputChange}
            placeholder="Brief summary of the interaction"
          />
          {formErrors.summary && (
            <p className="mt-1 text-red-600 text-xs flex items-center">
              <AlertCircle size={12} className="mr-1" /> {formErrors.summary}
            </p>
          )}
        </div>
        
        {/* Attachments */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Attachments
          </label>
          
          <div className="mb-3">
            <input
              type="file"
              id="file-upload"
              className="hidden"
              onChange={handleFileUpload}
              multiple
            />
            <label 
              htmlFor="file-upload" 
              className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              <Upload size={16} className="mr-2" />
              Upload Files
            </label>
            <p className="mt-1 text-xs text-gray-500">
              You can upload multiple files. Maximum size per file: 10MB.
            </p>
          </div>
          
          {formData.attachments.length > 0 && (
            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200">
              <p className="text-xs font-medium text-gray-700 mb-2">Attached Files:</p>
              <div className="flex flex-wrap gap-2">
                {formData.attachments.map((attachment) => (
                  <div key={attachment.id} className="bg-white rounded p-2 border flex items-center">
                    <span className="text-sm truncate max-w-[200px]">{attachment.name}</span>
                    <span className="text-xs text-gray-500 ml-2">{attachment.size}</span>
                    <button 
                      type="button"
                      onClick={() => handleRemoveAttachment(attachment.id)}
                      className="ml-2 text-gray-400 hover:text-red-500"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        
        {/* Additional Information */}
        <h3 className="text-lg font-medium text-gray-900 mb-4">Additional Information</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {/* Assigned To */}
          <div>
            <label htmlFor="assignedTo" className="block text-sm font-medium text-gray-700 mb-1">
              Assigned To
            </label>
            <select
              id="assignedTo"
              name="assignedTo"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.assignedTo}
              onChange={handleInputChange}
            >
              <option value="">Unassigned</option>
              {staffMembers.map((staff) => (
                <option key={staff.id} value={staff.id}>{staff.name}</option>
              ))}
            </select>
          </div>
          
          {/* Related Entity */}
          <div>
            <label htmlFor="relatedType" className="block text-sm font-medium text-gray-700 mb-1">
              Related To
            </label>
            <select
              id="relatedType"
              name="relatedType"
              className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
              value={formData.relatedType}
              onChange={handleInputChange}
            >
              <option value="">Not Related</option>
              {relatedEntityTypes.map((entity) => (
                <option key={entity.id} value={entity.id}>{entity.label}</option>
              ))}
            </select>
            {formData.relatedType && (
              <div className="mt-2">
                <input
                  type="text"
                  name="relatedName"
                  className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={formData.relatedName}
                  onChange={handleInputChange}
                  placeholder={`Enter ${formData.relatedType} name or ID`}
                />
              </div>
            )}
          </div>
        </div>
        
        {/* Tags */}
        <div className="mb-6">
          <label htmlFor="tags" className="block text-sm font-medium text-gray-700 mb-2">
            Tags
          </label>
          
          <div className="mb-2">
            <div className="flex">
              <div className="relative flex-grow">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Tag size={18} className="text-gray-400" />
                </div>
                <input
                  id="tags"
                  type="text"
                  className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                  value={tagInput}
                  onChange={(e) => setTagInput(e.target.value)}
                  onKeyDown={handleTagKeyDown}
                  placeholder="Add tag and press Enter"
                />
              </div>
            </div>
            
            <div className="mt-2 flex flex-wrap gap-2">
              {tagSuggestions.map((tag) => (
                <button
                  key={tag}
                  type="button"
                  onClick={() => handleAddTag(tag)}
                  className="px-2.5 py-1 bg-gray-100 hover:bg-gray-200 text-gray-700 text-xs rounded-full"
                >
                  + {tag}
                </button>
              ))}
            </div>
          </div>
          
          {formData.tags.length > 0 && (
            <div className="flex flex-wrap gap-2 mt-3">
              {formData.tags.map((tag) => (
                <span 
                  key={tag} 
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-800"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleRemoveTag(tag)}
                    className="ml-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-200"
                  >
                    <span className="sr-only">Remove tag</span>
                    <X size={12} />
                  </button>
                </span>
              ))}
            </div>
          )}
        </div>
        
        {/* Next Steps */}
        <div className="mb-6">
          <label htmlFor="nextSteps" className="block text-sm font-medium text-gray-700 mb-1">
            Next Steps
          </label>
          <textarea
            id="nextSteps"
            name="nextSteps"
            rows="3"
            className="block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
            value={formData.nextSteps}
            onChange={handleInputChange}
            placeholder="Follow-up actions or next steps..."
          />
        </div>
        
        {/* Follow-up */}
        <div className="mb-6">
          <label className="flex items-center cursor-pointer">
            <input 
              type="checkbox" 
              className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              checked={!!formData.followUpDate}
              onChange={(e) => {
                if (!e.target.checked) {
                  setFormData(prev => ({
                    ...prev,
                    followUpDate: '',
                    followUpTime: ''
                  }));
                } else {
                  const today = new Date();
                  today.setDate(today.getDate() + 3); // Set default follow-up 3 days ahead
                  
                  setFormData(prev => ({
                    ...prev,
                    followUpDate: today.toISOString().split('T')[0],
                    followUpTime: '10:00'
                  }));
                }
              }}
            />
            <span className="ml-2 text-sm text-gray-700">Schedule Follow-up</span>
          </label>
          
          {formData.followUpDate && (
            <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div>
                <label htmlFor="followUpDate" className="block text-sm font-medium text-gray-700 mb-1">
                  Follow-up Date
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Calendar size={18} className="text-gray-400" />
                  </div>
                  <input
                    id="followUpDate"
                    name="followUpDate"
                    type="date"
                    className="pl-10 block w-full rounded-lg border border-gray-300 py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                    value={formData.followUpDate}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="followUpTime" className="block text-sm font-medium text-gray-700 mb-1">
                  Follow-up Time
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Clock size={18} className="text-gray-400" />
                  </div>
                  <input
                    id="followUpTime"
                    name="followUpTime"
                    type="time"
                    className={`pl-10 block w-full rounded-lg border ${
                      formErrors.followUpTime ? 'border-red-300' : 'border-gray-300'
                    } py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]`}
                    value={formData.followUpTime}
                    onChange={handleInputChange}
                  />
                </div>
                {formErrors.followUpTime && (
                  <p className="mt-1 text-red-600 text-xs flex items-center">
                    <AlertCircle size={12} className="mr-1" /> {formErrors.followUpTime}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
        
        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
          <button
            type="button"
            onClick={() => navigate('/crm/interactions')}
            className="px-6 py-2.5 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
            disabled={saving}
          >
            Cancel
          </button>
          
          <button
            type="submit"
            disabled={saving}
            className={`px-6 py-2.5 rounded-lg text-white font-medium flex items-center ${
              saving ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
            }`}
          >
            {saving ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <Save size={18} className="mr-2" />
                {isEditing ? 'Update Interaction' : 'Save Interaction'}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default InteractionForm;