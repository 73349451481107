// src/components/crm/sales/SalesPipeline.jsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  PlusCircle, Filter, Search, User, 
  Building, DollarSign, Calendar, ChevronDown,
  Check, ChevronRight, ExternalLink, Users,
  SlidersHorizontal, ListFilter, RefreshCw
} from 'lucide-react';

const SalesPipeline = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterValue, setFilterValue] = useState('all');
  const [opportunities, setOpportunities] = useState([]);
  const [draggedOpportunity, setDraggedOpportunity] = useState(null);
  const navigate = useNavigate();
  const [viewType, setViewType] = useState('kanban'); 
  const [sortBy, setSortBy] = useState('updated'); 
  const [sortDirection, setSortDirection] = useState('desc');
  
  const stages = [
    { id: 'lead', name: 'Lead', color: 'bg-blue-500' },
    { id: 'qualified', name: 'Qualified', color: 'bg-purple-500' },
    { id: 'proposal', name: 'Proposal', color: 'bg-amber-500' },
    { id: 'negotiation', name: 'Negotiation', color: 'bg-orange-500' },
    { id: 'closed_won', name: 'Closed Won', color: 'bg-green-500' },
    { id: 'closed_lost', name: 'Closed Lost', color: 'bg-red-500' }
  ];
  
  // Fetch opportunities
  useEffect(() => {
    const fetchOpportunities = async () => {
      setIsLoading(true);
      
      try {
        // In a real app, this would be an API call
        await new Promise(r => setTimeout(r, 800));
        
        // Mock opportunity data
        const mockData = [
          {
            id: '1',
            title: 'Enterprise Software Upgrade',
            company: 'Acme Corporation',
            stage: 'lead',
            amount: 45000,
            closeDate: '2025-05-15',
            probability: 20,
            assignedTo: 'Sarah Kim',
            contactName: 'John Smith',
            contactEmail: 'john@acmecorp.com'
          },
          {
            id: '2',
            title: 'Annual Maintenance Contract',
            company: 'Beta Industries',
            stage: 'qualified',
            amount: 28000,
            closeDate: '2025-04-30',
            probability: 60,
            assignedTo: 'Michael Johnson',
            contactName: 'Jane Doe',
            contactEmail: 'jane@betaindustries.com'
          },
          {
            id: '3',
            title: 'Cloud Migration Project',
            company: 'Gamma Tech',
            stage: 'proposal',
            amount: 75000,
            closeDate: '2025-06-15',
            probability: 40,
            assignedTo: 'David Chen',
            contactName: 'Robert Lee',
            contactEmail: 'robert@gammatech.com'
          },
          {
            id: '4',
            title: 'Data Analytics Package',
            company: 'Delta Systems',
            stage: 'negotiation',
            amount: 35000,
            closeDate: '2025-04-10',
            probability: 80,
            assignedTo: 'Sarah Kim',
            contactName: 'Emily Davis',
            contactEmail: 'emily@deltasystems.com'
          },
          {
            id: '5',
            title: 'Network Security Upgrade',
            company: 'Epsilon Corp',
            stage: 'closed_won',
            amount: 52000,
            closeDate: '2025-03-20',
            probability: 100,
            assignedTo: 'Michael Johnson',
            contactName: 'Alex Wong',
            contactEmail: 'alex@epsiloncorp.com'
          },
          {
            id: '6',
            title: 'Office Equipment Renewal',
            company: 'Zeta LLC',
            stage: 'closed_lost',
            amount: 18000,
            closeDate: '2025-03-05',
            probability: 0,
            assignedTo: 'David Chen',
            contactName: 'Maria Garcia',
            contactEmail: 'maria@zetallc.com'
          },
          {
            id: '7',
            title: 'Software Development',
            company: 'Theta Solutions',
            stage: 'proposal',
            amount: 65000,
            closeDate: '2025-05-20',
            probability: 50,
            assignedTo: 'Sarah Kim',
            contactName: 'James Wilson',
            contactEmail: 'james@thetasolutions.com'
          }
        ];
        
        setOpportunities(mockData);
      } catch (error) {
        console.error('Error fetching opportunities:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchOpportunities();
  }, []);

  const refreshData = async () => {
    setIsLoading(true);
    try {
      // In a real app, this would fetch fresh data
      await new Promise(r => setTimeout(r, 500));
      // The mock data refresh would be here
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  // Filter and search opportunities
  const getFilteredOpportunities = () => {
    let filtered = opportunities;
    
    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter(opp => 
        opp.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        opp.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
        opp.contactName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Filter by assigned to
    if (filterValue !== 'all') {
      filtered = filtered.filter(opp => 
        opp.assignedTo.toLowerCase() === filterValue.toLowerCase()
      );
    }
    
    return filtered;
  };

  // Get opportunities by stage
  const getOpportunitiesByStage = (stage) => {
    return getFilteredOpportunities().filter(opp => opp.stage === stage);
  };

  // Calculate total value for a stage
  const calculateStageValue = (stage) => {
    return getOpportunitiesByStage(stage)
      .reduce((sum, opp) => sum + opp.amount, 0);
  };

  // Handle drag start
  const handleDragStart = (e, opportunity) => {
    setDraggedOpportunity(opportunity);
    e.dataTransfer.setData('text/plain', opportunity.id);
    e.dataTransfer.effectAllowed = 'move';
  };

  // Handle drag over
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  // Handle drop
  const handleDrop = (e, targetStage) => {
    e.preventDefault();
    
    if (!draggedOpportunity) return;
    
    // Update opportunity stage
    const updatedOpportunities = opportunities.map(opp => {
      if (opp.id === draggedOpportunity.id) {
        // Update probability based on stage
        let probability = opp.probability;
        switch (targetStage) {
          case 'lead':
            probability = 20;
            break;
          case 'qualified':
            probability = 40;
            break;
          case 'proposal':
            probability = 60;
            break;
          case 'negotiation':
            probability = 80;
            break;
          case 'closed_won':
            probability = 100;
            break;
          case 'closed_lost':
            probability = 0;
            break;
          default:
            break;
        }
        
        return {
          ...opp,
          stage: targetStage,
          probability
        };
      }
      return opp;
    });
    
    setOpportunities(updatedOpportunities);
    setDraggedOpportunity(null);
  };

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  // Calculate total pipeline value
  const calculateTotalValue = () => {
    return getFilteredOpportunities().reduce((total, opp) => total + opp.amount, 0);
  };

  // Calculate weighted pipeline value
  const calculateWeightedValue = () => {
    return getFilteredOpportunities()
      .reduce((total, opp) => total + (opp.amount * opp.probability / 100), 0);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  const sortOpportunities = (opportunities) => {
    return [...opportunities].sort((a, b) => {
      let comparison = 0;
      
      switch (sortBy) {
        case 'value':
          comparison = a.amount - b.amount;
          break;
        case 'closeDate':
          comparison = new Date(a.closeDate) - new Date(b.closeDate);
          break;
        case 'updated':
        default:
          // In a real app, you'd use updatedAt timestamps
          comparison = a.id.localeCompare(b.id);
          break;
      }
      
      return sortDirection === 'asc' ? comparison : -comparison;
    });
  };

  return (
    <div>
      {/* Header with summary stats */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-6">
        <div className="flex flex-wrap justify-between items-center">
          <div>
            <h3 className="text-lg font-medium text-gray-900">Sales Pipeline</h3>
            <p className="text-sm text-gray-500">Track and manage your sales opportunities</p>
          </div>
          
          <div className="flex items-center space-x-4 mt-4 sm:mt-0">
            <div className="flex flex-col">
              <span className="text-sm text-gray-500">Pipeline Value</span>
              <span className="text-xl font-bold text-gray-800">{formatCurrency(calculateTotalValue())}</span>
            </div>
            
            <div className="border-l border-gray-200 h-10 mx-2 hidden sm:block"></div>
            
            <div className="flex flex-col">
              <span className="text-sm text-gray-500">Weighted Value</span>
              <span className="text-xl font-bold text-[#190192]">{formatCurrency(calculateWeightedValue())}</span>
            </div>
            
            <Link
              to="/crm/sales/new"
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <PlusCircle size={18} className="mr-2" />
              Add Opportunity
            </Link>
          </div>
        </div>
      </div>
      
      {/* Search and Filter */}
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search opportunities..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="relative">
          <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <select
            className="pl-10 pr-8 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20 appearance-none"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
          >
            <option value="all">All Assigned To</option>
            <option value="Sarah Kim">Sarah Kim</option>
            <option value="Michael Johnson">Michael Johnson</option>
            <option value="David Chen">David Chen</option>
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronDown size={16} className="text-gray-400" />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-3 mb-6">
        <div className="bg-white rounded-lg border border-gray-200 flex p-1">
          <button
            onClick={() => setViewType('kanban')}
            className={`px-3 py-1.5 rounded ${
              viewType === 'kanban' 
                ? 'bg-[#190192] text-white' 
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <SlidersHorizontal size={16} className="mr-1 inline-block" /> Kanban
          </button>
          <button
            onClick={() => setViewType('list')}
            className={`px-3 py-1.5 rounded ${
              viewType === 'list' 
                ? 'bg-[#190192] text-white' 
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <ListFilter size={16} className="mr-1 inline-block" /> List
          </button>
        </div>
        
        <div className="relative">
          <button
            className="px-3 py-1.5 bg-white border border-gray-200 rounded-lg flex items-center text-gray-600 hover:bg-gray-50"
            onClick={() => refreshData()}
          >
            <RefreshCw size={16} className="mr-1" /> Refresh
          </button>
        </div>
        
        <div className="relative">
          <select
            className="px-3 py-1.5 bg-white border border-gray-200 rounded-lg appearance-none pr-8 text-gray-600"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="updated">Sort by: Last Updated</option>
            <option value="value">Sort by: Value</option>
            <option value="closeDate">Sort by: Close Date</option>
          </select>
          <ChevronDown size={16} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
        </div>
        
        <button
          onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
          className="p-1.5 bg-white border border-gray-200 rounded-lg text-gray-600 hover:bg-gray-50"
          title={sortDirection === 'asc' ? 'Sort Ascending' : 'Sort Descending'}
        >
          {sortDirection === 'asc' ? '↑' : '↓'}
        </button>
      </div>

      // Modify the main content section to handle different views
      {viewType === 'kanban' ? (
        // Existing Kanban view goes here - the flex space-x-4 overflow-x-auto section
        <div className="flex space-x-4 overflow-x-auto pb-6">
          {/* Existing stages map and card display */}
        </div>
      ) : (
        // List view
        <div className="bg-white rounded-xl shadow-md overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Opportunity</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stage</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Close Date</th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortOpportunities(getFilteredOpportunities()).map(opportunity => {
                // Get stage info for display
                const stage = stages.find(s => s.id === opportunity.stage);
                
                return (
                  <tr key={opportunity.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{opportunity.title}</div>
                          {opportunity.contactName && (
                            <div className="text-xs text-gray-500">Contact: {opportunity.contactName}</div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {opportunity.company}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-${stage.color.split('-')[1]}-100 text-${stage.color.split('-')[1]}-800`}>
                        {stage.name}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {opportunity.closeDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-medium text-gray-900">
                      {formatCurrency(opportunity.amount)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link
                        to={`/crm/sales/${opportunity.id}`}
                        className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                      >
                        View <ChevronRight size={16} className="ml-1" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      
      {/* Kanban Board */}
      <div className="flex space-x-4 overflow-x-auto pb-6">
        {stages.map((stage) => (
          <div 
            key={stage.id}
            className="flex-shrink-0 w-80 flex flex-col"
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, stage.id)}
          >
            {/* Stage Header */}
            <div className={`${stage.color} text-white px-4 py-3 rounded-t-lg`}>
              <div className="flex justify-between items-center">
                <div className="font-medium">{stage.name}</div>
                <div className="text-sm">
                  {getOpportunitiesByStage(stage.id).length} • {formatCurrency(calculateStageValue(stage.id))}
                </div>
              </div>
            </div>
            
            {/* Stage Content */}
            <div className="bg-gray-50 p-2 rounded-b-lg flex-1 min-h-[400px]">
              {getOpportunitiesByStage(stage.id).length === 0 ? (
                <div className="flex items-center justify-center h-20 border border-dashed border-gray-300 rounded-lg bg-white m-1">
                  <p className="text-sm text-gray-500">No opportunities</p>
                </div>
              ) : (
                <div className="space-y-2">
                  {getOpportunitiesByStage(stage.id).map((opportunity) => (
                    <div
                      key={opportunity.id}
                      className="bg-white p-3 rounded-lg shadow-sm border border-gray-200 cursor-move"
                      draggable
                      onDragStart={(e) => handleDragStart(e, opportunity)}
                    >
                      <div className="flex justify-between items-start mb-2">
                        <h4 className="font-medium text-gray-900 text-sm">{opportunity.title}</h4>
                        <span className="text-sm font-bold text-[#190192]">
                          {formatCurrency(opportunity.amount)}
                        </span>
                      </div>
                      <div className="flex items-center text-xs text-gray-500 mb-2">
                        <Building size={12} className="mr-1" />
                        <span>{opportunity.company}</span>
                      </div>
                      {opportunity.contactName && (
                        <div className="flex items-center text-xs text-gray-500 mb-2">
                          <User size={12} className="mr-1" />
                          <span>{opportunity.contactName}</span>
                        </div>
                      )}
                      <div className="flex items-center text-xs text-gray-500 mb-2">
                        <Calendar size={12} className="mr-1" />
                        <span>Close: {opportunity.closeDate}</span>
                      </div>
                      <div className="flex items-center justify-between mt-3 pt-2 border-t border-gray-100">
                        <div className="flex items-center text-xs text-gray-500">
                          <Users size={12} className="mr-1" />
                          <span>{opportunity.assignedTo}</span>
                        </div>
                        <Link
                          to={`/crm/sales/${opportunity.id}`}
                          className="text-xs text-[#190192] hover:text-[#2C0DB5] flex items-center"
                        >
                          View <ChevronRight size={12} className="ml-1" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              
              <div className="mt-2 mx-1">
                <Link
                  to={`/crm/sales/new?stage=${stage.id}`}
                  className="flex items-center justify-center px-3 py-2 border border-dashed border-gray-300 rounded-lg text-sm text-gray-600 hover:text-[#190192] hover:border-[#190192]"
                >
                  <PlusCircle size={14} className="mr-1" />
                  Add Card
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SalesPipeline;