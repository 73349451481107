// src/pages/HumanCapitalPage.jsx
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Users, Briefcase, UserPlus, FileCheck, Award } from 'lucide-react';

const HumanCapitalPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // Tab definitions with icons, labels and paths
  const tabs = [
    { 
      id: 'team', 
      label: 'Team', 
      icon: Users,
      path: '/human-capital/team',
      active: currentPath.includes('/human-capital/team') || currentPath === '/human-capital'
    },
    { 
      id: 'recruitment', 
      label: 'Recruitment', 
      icon: Briefcase,
      path: '/human-capital/recruitment',
      active: currentPath.includes('/human-capital/recruitment')
    },
    { 
      id: 'onboarding', 
      label: 'Onboarding', 
      icon: UserPlus,
      path: '/human-capital/onboarding',
      active: currentPath.includes('/human-capital/onboarding')
    },
    { 
      id: 'employees', 
      label: 'Employees', 
      icon: FileCheck,
      path: '/human-capital/employees',
      active: currentPath.includes('/human-capital/employees')
    },
    { 
      id: 'performance', 
      label: 'Performance', 
      icon: Award,
      path: '/human-capital/performance',
      active: currentPath.includes('/human-capital/performance')
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
          <div className="flex justify-between items-center">
              <p className="text-purple-800 text-sm ml-2">
                Manage your team, recruitment, onboarding, and employee performance.
              </p>
        </div>

        {/* Tabs */}
        <div className="px-6 border-b border-gray-200">
          <div className="flex overflow-x-auto">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`flex items-center px-4 py-3 font-medium text-sm whitespace-nowrap border-b-2 ${
                  tab.active
                    ? 'border-[#190192] text-[#190192]'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => navigate(tab.path)}
              >
                <tab.icon className="mr-2" size={18} />
                {tab.label}
              </button>
            ))}
          </div>
      </div>

      {/* Nested Routes */}
      <Outlet />
    </div>
  );
};

export default HumanCapitalPage;