// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Mail, Lock, AlertCircle, ArrowRight, Eye, EyeOff } from 'lucide-react';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [resetError, setResetError] = useState('');
  
  const { login, googleSignIn, resetPassword, error, currentUser } = useAuth();
  const navigate = useNavigate();
  
  // Check if user was recently logged out
  const wasLoggedOut = localStorage.getItem('bizcentral_logged_out') === 'true';
  
  // If user is already logged in, redirect to dashboard
  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);
  
  // Handle form submission for email/password login
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // If the user was logged out, force Google sign-in
    if (wasLoggedOut) {
      handleGoogleSignIn();
      return;
    }
    
    // Validate form
    if (!email.trim()) {
      setFormError('Email is required');
      return;
    }
    
    if (!password) {
      setFormError('Password is required');
      return;
    }
    
    try {
      setFormError('');
      setIsLoading(true);
      
      // Login with email/password
      await login(email, password);
      
      // Clear logged out flag upon successful login
      localStorage.removeItem('bizcentral_logged_out');
      
      // If login successful, the AuthContext will update and redirect the user
    } catch (err) {
      // The error is handled by AuthContext, but we reset loading state
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Handle Google Sign-in
  const handleGoogleSignIn = async () => {
    try {
      setFormError('');
      setIsLoading(true);
      
      await googleSignIn();
      
      // Clear logged out flag upon successful login
      localStorage.removeItem('bizcentral_logged_out');
      
      // If successful, the AuthContext will update and redirect the user
    } catch (err) {
      // The error is handled by AuthContext, but we reset loading state
      console.error('Google sign-in error:', err);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Handle password reset
  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (!resetEmail.trim()) {
      setResetError('Please enter your email address');
      return;
    }
    
    try {
      setResetLoading(true);
      setResetError('');
      
      await resetPassword(resetEmail);
      setResetSuccess(true);
    } catch (err) {
      setResetError(err.message || 'Failed to send reset email. Please try again.');
    } finally {
      setResetLoading(false);
    }
  };
  
  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
      <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md p-6">
        <div className="mb-6 text-center">
          <h2 className="text-2xl font-bold text-gray-900">Sign In to Your Account</h2>
          <p className="mt-2 text-sm text-gray-600">
            Don't have an account yet?{' '}
            <Link to="/register" className="text-[#190192] hover:text-[#2C0DB5] font-medium">
              Sign up
            </Link>
          </p>
        </div>
        
        {/* Error Alert */}
        {(formError || error) && (
          <div className="mb-4 p-3 bg-red-50 rounded-lg flex items-start">
            <AlertCircle size={20} className="text-red-600 mr-2 flex-shrink-0 mt-0.5" />
            <span className="text-red-800 text-sm">{formError || error}</span>
          </div>
        )}
        
        {/* Google auth notice when forced */}
        {wasLoggedOut && (
          <div className="mb-4 p-3 bg-amber-50 rounded-lg">
            <p className="text-amber-800 text-sm">
              For security reasons, please use Google Sign-in to continue after logging out.
            </p>
          </div>
        )}
        
        {/* Login Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email Input */}
          <div>
            <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={18} className="text-gray-400" />
              </div>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="pl-10 w-full py-2.5 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="name@company.com"
                disabled={isLoading || wasLoggedOut}
              />
            </div>
          </div>
          
          {/* Password Input */}
          <div>
            <div className="flex justify-between mb-1">
              <label htmlFor="password" className="text-sm font-medium text-gray-700">
                Password
              </label>
              <button 
                type="button"
                onClick={() => setShowResetModal(true)} 
                className="text-sm text-[#190192] hover:text-[#2C0DB5]"
              >
                Forgot password?
              </button>
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock size={18} className="text-gray-400" />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="pl-10 pr-10 w-full py-2.5 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                placeholder="••••••••"
                disabled={isLoading || wasLoggedOut}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 hover:text-gray-700"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
              </button>
            </div>
          </div>
          
          {/* Remember Me Checkbox */}
          <div className="flex items-center">
            <input
              id="remember"
              type="checkbox"
              className="h-4 w-4 text-[#190192] focus:ring-[#190192] border-gray-300 rounded"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              disabled={isLoading || wasLoggedOut}
            />
            <label htmlFor="remember" className="ml-2 block text-sm text-gray-700">
              Remember me
            </label>
          </div>
          
          {/* Submit Button - Hide if forced Google login */}
          {!wasLoggedOut && (
            <button
              type="submit"
              disabled={isLoading}
              className={`w-full py-2.5 px-5 flex justify-center items-center rounded-lg text-white ${
                isLoading ? 'bg-purple-400 cursor-not-allowed' : 'bg-[#190192] hover:bg-[#2C0DB5]'
              }`}
            >
              {isLoading ? (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : (
                <>
                  Sign In <ArrowRight size={18} className="ml-1" />
                </>
              )}
            </button>
          )}
          
          {/* Divider */}
          <div className="flex items-center my-4">
            <div className="flex-1 border-t border-gray-300"></div>
            <div className="px-3 text-sm text-gray-500">{wasLoggedOut ? 'SIGN IN WITH' : 'OR'}</div>
            <div className="flex-1 border-t border-gray-300"></div>
          </div>
          
          {/* Google Sign In Button */}
          <button
            type="button"
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            className="w-full py-2.5 px-5 flex justify-center items-center gap-2 border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20px" height="20px">
              <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" />
              <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" />
              <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" />
              <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" />
            </svg>
            Sign in with Google
          </button>
        </form>
      </div>
      
      {/* Password Reset Modal */}
      {showResetModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Reset Password</h3>
            
            {resetSuccess ? (
              <div>
                <div className="mb-4 p-3 bg-green-50 rounded-lg">
                  <p className="text-green-800 text-sm">
                    Password reset email sent! Check your inbox and follow the instructions to reset your password.
                  </p>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      setShowResetModal(false);
                      setResetSuccess(false);
                      setResetEmail('');
                    }}
                    className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5]"
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <form onSubmit={handleResetPassword}>
                <p className="text-gray-600 mb-4">
                  Enter your email address and we'll send you a link to reset your password.
                </p>
                
                {resetError && (
                  <div className="mb-4 p-3 bg-red-50 rounded-lg">
                    <p className="text-red-800 text-sm">{resetError}</p>
                  </div>
                )}
                
                <div className="mb-4">
                  <label htmlFor="resetEmail" className="block text-sm font-medium text-gray-700 mb-1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="resetEmail"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    className="w-full py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#190192] focus:border-[#190192]"
                    placeholder="Enter your email"
                    disabled={resetLoading}
                  />
                </div>
                
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowResetModal(false)}
                    className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50"
                    disabled={resetLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={resetLoading}
                    className={`px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center ${
                      resetLoading ? 'opacity-70 cursor-not-allowed' : ''
                    }`}
                  >
                    {resetLoading ? (
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : null}
                    Send Reset Link
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;