// src/components/human-capital/recruitment/JobDetail.js
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, Edit, Trash2, Briefcase, 
  Calendar, Clock, DollarSign, MapPin, 
  User, Users, FileText, CheckCircle, X,
  UserCheck, Download, PlusCircle, Mail,
  ExternalLink, Phone, Star, AlertCircle
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/config';

const JobDetail = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [activeTab, setActiveTab] = useState('overview');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Fetch job details and applicants
  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!currentUser) return;
      
      setLoading(true);
      
      try {
        // Fetch job document
        const jobRef = doc(db, 'jobs', jobId);
        const jobDoc = await getDoc(jobRef);
        
        if (jobDoc.exists()) {
          const jobData = {
            id: jobDoc.id,
            ...jobDoc.data()
          };
          
          setJob(jobData);
          
          // Fetch applicants for this job
          const applicantsQuery = query(
            collection(db, 'applicants'),
            where('jobId', '==', jobId)
          );
          
          const applicantsSnapshot = await getDocs(applicantsQuery);
          const applicantsData = [];
          
          applicantsSnapshot.forEach(doc => {
            applicantsData.push({
              id: doc.id,
              ...doc.data()
            });
          });
          
          setApplicants(applicantsData);
        } else {
          // If we don't have data in Firestore yet, use mock data
          const mockJob = {
            id: jobId,
            title: 'Senior Full Stack Developer',
            department: 'Engineering',
            location: 'San Francisco, CA (Remote Available)',
            type: 'Full-time',
            experience: '5+ years',
            salary: '$120,000 - $150,000',
            postedDate: new Date('2025-03-01'),
            closingDate: new Date('2025-04-15'),
            status: 'active',
            description: `
              <p>We are seeking an experienced Full Stack Developer to join our engineering team. The ideal candidate will be responsible for developing and maintaining web applications using modern technologies.</p>
              
              <h4>Responsibilities:</h4>
              <ul>
                <li>Design and develop high-quality web applications</li>
                <li>Work with both front-end and back-end technologies</li>
                <li>Collaborate with cross-functional teams</li>
                <li>Write clean, efficient, and well-documented code</li>
                <li>Participate in code reviews and mentor junior developers</li>
              </ul>
            `,
            requirements: `
              <h4>Requirements:</h4>
              <ul>
                <li>5+ years of experience in full stack development</li>
                <li>Proficiency in JavaScript, TypeScript, React, and Node.js</li>
                <li>Experience with SQL and NoSQL databases</li>
                <li>Knowledge of cloud services (AWS/Azure/GCP)</li>
                <li>Bachelor's degree in Computer Science or related field</li>
              </ul>
            `,
            benefits: `
              <h4>Benefits:</h4>
              <ul>
                <li>Competitive salary and equity options</li>
                <li>Health, dental, and vision insurance</li>
                <li>401(k) matching</li>
                <li>Flexible working hours and remote work options</li>
                <li>Professional development budget</li>
                <li>Paid time off and parental leave</li>
              </ul>
            `,
            skills: ['JavaScript', 'TypeScript', 'React', 'Node.js', 'SQL', 'NoSQL', 'AWS'],
            applicationUrl: 'https://careers.example.com/apply'
          };
          
          setJob(mockJob);
          
          // Mock applicants
          const mockApplicants = [
            {
              id: '1',
              name: 'Sarah Johnson',
              email: 'sarah.johnson@example.com',
              phone: '(555) 123-4567',
              appliedDate: new Date('2025-03-05'),
              resumeUrl: '#',
              status: 'screening',
              jobId: jobId
            },
            {
              id: '2',
              name: 'Michael Chen',
              email: 'michael.chen@example.com',
              phone: '(555) 987-6543',
              appliedDate: new Date('2025-03-10'),
              resumeUrl: '#',
              status: 'interview',
              jobId: jobId
            },
            {
              id: '3',
              name: 'Emily Rodriguez',
              email: 'emily.rodriguez@example.com',
              phone: '(555) 234-5678',
              appliedDate: new Date('2025-03-08'),
              resumeUrl: '#',
              status: 'applied',
              jobId: jobId
            }
          ];
          
          setApplicants(mockApplicants);
        }
      } catch (error) {
        console.error('Error fetching job details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId, currentUser]);

  // Handle job deletion
  const handleDeleteJob = async () => {
    try {
      setLoading(true);
      
      // Delete the job document
      await deleteDoc(doc(db, 'jobs', jobId));
      
      // Navigate back to the jobs list
      navigate('/human-capital/recruitment');
    } catch (error) {
      console.error('Error deleting job:', error);
      alert('Failed to delete job. Please try again.');
    } finally {
      setLoading(false);
      setDeleteModalOpen(false);
    }
  };

  // Format date
  const formatDate = (date) => {
    if (!date) return 'N/A';
    
    try {
      // Handle Firestore timestamp or JS Date
      const dateObject = date.toDate ? date.toDate() : new Date(date);
      
      return dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'N/A';
    }
  };
  
  // Get status badge style
  const getStatusBadge = (status) => {
    switch (status) {
      case 'active':
        return { color: 'bg-green-100 text-green-800', label: 'Active' };
      case 'closed':
        return { color: 'bg-gray-100 text-gray-800', label: 'Closed' };
      case 'draft':
        return { color: 'bg-yellow-100 text-yellow-800', label: 'Draft' };
      case 'paused':
        return { color: 'bg-blue-100 text-blue-800', label: 'Paused' };
      default:
        return { color: 'bg-gray-100 text-gray-800', label: status };
    }
  };
  
  // Get applicant status badge
  const getApplicantStatusBadge = (status) => {
    switch (status) {
      case 'applied':
        return { color: 'bg-blue-100 text-blue-800', label: 'Applied' };
      case 'screening':
        return { color: 'bg-yellow-100 text-yellow-800', label: 'Screening' };
      case 'interview':
        return { color: 'bg-purple-100 text-purple-800', label: 'Interview' };
      case 'offer':
        return { color: 'bg-green-100 text-green-800', label: 'Offer' };
      case 'hired':
        return { color: 'bg-green-600 text-white', label: 'Hired' };
      case 'rejected':
        return { color: 'bg-red-100 text-red-800', label: 'Rejected' };
      default:
        return { color: 'bg-gray-100 text-gray-800', label: status };
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6 flex justify-center items-center h-64">
        <div className="animate-spin h-10 w-10 border-4 border-[#190192] rounded-full border-t-transparent"></div>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="bg-white rounded-xl shadow-md p-6">
        <div className="text-center py-12">
          <X size={48} className="mx-auto text-red-500 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Job posting not found</h3>
          <p className="text-gray-500 mb-6">The job posting you're looking for doesn't exist or has been removed.</p>
          <button
            onClick={() => navigate('/human-capital/recruitment')}
            className="px-4 py-2 bg-[#190192] rounded-lg text-white hover:bg-[#2C0DB5]"
          >
            Back to Jobs
          </button>
        </div>
      </div>
    );
  }

  const statusBadge = getStatusBadge(job.status);

  return (
    <>
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {/* Header Actions */}
        <div className="px-6 pt-6 pb-4 flex justify-between items-start">
          <Link
            to="/human-capital/recruitment"
            className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
          >
            <ChevronLeft size={16} className="mr-1" />
            Back to Jobs
          </Link>
          
          <div className="flex space-x-3">
            <button className="p-2 text-gray-500 hover:text-[#190192] hover:bg-gray-100 rounded-lg">
              <Download size={20} />
            </button>
            <Link
              to={`/human-capital/recruitment/jobs/edit/${jobId}`}
              className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
            >
              <Edit size={16} className="mr-2" />
              Edit
            </Link>
            <button
              onClick={() => setDeleteModalOpen(true)}
              className="px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 flex items-center"
            >
              <Trash2 size={16} className="mr-2" />
              Delete
            </button>
          </div>
        </div>
        
        {/* Job Header */}
        <div className="px-6 pb-6 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row sm:items-center gap-6">
            <div className="w-16 h-16 rounded-lg bg-purple-100 flex items-center justify-center flex-shrink-0 text-[#190192]">
              <Briefcase size={32} />
            </div>
            
            <div className="flex-1">
              <div className="flex flex-wrap items-center gap-2 mb-1">
                <h1 className="text-2xl font-bold text-gray-900">{job.title}</h1>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusBadge.color}`}>
                  {statusBadge.label}
                </span>
              </div>
              
              <p className="text-gray-600">{job.department} • {job.type}</p>
              
              <div className="mt-2 flex flex-wrap items-center gap-x-4 gap-y-2 text-sm text-gray-500">
                <div className="flex items-center">
                  <MapPin size={16} className="mr-1 text-gray-400" />
                  {job.location}
                </div>
                
                <div className="flex items-center">
                  <Calendar size={16} className="mr-1 text-gray-400" />
                  Posted: {formatDate(job.postedDate)}
                </div>
                
                {job.closingDate && (
                  <div className="flex items-center">
                    <Clock size={16} className="mr-1 text-gray-400" />
                    Closes: {formatDate(job.closingDate)}
                  </div>
                )}
                
                {job.salary && (
                  <div className="flex items-center">
                    <DollarSign size={16} className="mr-1 text-gray-400" />
                    {job.salary}
                  </div>
                )}
              </div>
            </div>
            
            <div className="sm:text-right">
              <Link
                to={`/human-capital/recruitment/applicants?jobId=${jobId}`}
                className="block w-full sm:w-auto px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center justify-center sm:justify-start"
              >
                <UserCheck size={16} className="mr-2" />
                View All Applicants ({applicants.length})
              </Link>
              
              {job.applicationUrl && (
                <a
                    href={job.applicationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-2 block w-full sm:w-auto px-4 py-2 border border-[#190192] text-[#190192] rounded-lg hover:bg-purple-50 flex items-center justify-center sm:justify-start"
                >
                    <ExternalLink size={16} className="mr-2" />
                    Application Link
                </a>
                )}
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <nav className="flex px-6 -mb-px">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('applicants')}
              className={`py-4 px-4 text-sm font-medium border-b-2 ${
                activeTab === 'applicants'
                  ? 'border-[#190192] text-[#190192]' 
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Applicants ({applicants.length})
            </button>
          </nav>
        </div>
        
        {/* Tab Content */}
        <div className="p-6">
          {activeTab === 'overview' ? (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Job Details */}
              <div className="lg:col-span-2 space-y-8">
                {/* Job Description */}
                {job.description && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Job Description</h3>
                    <div 
                      className="prose prose-sm max-w-none"
                      dangerouslySetInnerHTML={{ __html: job.description }}
                    />
                  </div>
                )}
                
                {/* Requirements */}
                {job.requirements && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Requirements</h3>
                    <div 
                      className="prose prose-sm max-w-none"
                      dangerouslySetInnerHTML={{ __html: job.requirements }}
                    />
                  </div>
                )}
                
                {/* Benefits */}
                {job.benefits && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Benefits</h3>
                    <div 
                      className="prose prose-sm max-w-none"
                      dangerouslySetInnerHTML={{ __html: job.benefits }}
                    />
                  </div>
                )}
                
                {/* Skills */}
                {job.skills && job.skills.length > 0 && (
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">Required Skills</h3>
                    <div className="flex flex-wrap gap-2">
                      {job.skills.map((skill, index) => (
                        <span 
                          key={index}
                          className="px-3 py-1 bg-gray-100 text-gray-800 rounded-full text-sm"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              
              {/* Right Column: Sidebar */}
              <div className="lg:col-span-1">
                {/* Job Summary */}
                <div className="bg-gray-50 rounded-xl p-5 mb-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Job Summary</h3>
                  
                  <div className="space-y-3">
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Department</span>
                      <span className="text-sm font-medium text-gray-900">{job.department}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Job Type</span>
                      <span className="text-sm font-medium text-gray-900">{job.type}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Experience</span>
                      <span className="text-sm font-medium text-gray-900">{job.experience || 'Not specified'}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Location</span>
                      <span className="text-sm font-medium text-gray-900">{job.location}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Salary</span>
                      <span className="text-sm font-medium text-gray-900">{job.salary || 'Not disclosed'}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Posting Date</span>
                      <span className="text-sm font-medium text-gray-900">{formatDate(job.postedDate)}</span>
                    </div>
                    
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Closing Date</span>
                      <span className="text-sm font-medium text-gray-900">{formatDate(job.closingDate)}</span>
                    </div>
                  </div>
                </div>
                
                {/* Recent Applicants */}
                <div className="bg-gray-50 rounded-xl p-5">
                  <div className="flex justify-between items-center mb-3">
                    <h3 className="text-lg font-medium text-gray-900">Recent Applicants</h3>
                    <Link
                      to={`/human-capital/recruitment/applicants?jobId=${jobId}`}
                      className="text-sm text-[#190192] hover:text-[#2C0DB5] font-medium flex items-center"
                    >
                      View All
                    </Link>
                  </div>
                  
                  {applicants.length === 0 ? (
                    <div className="text-center py-4">
                      <User size={24} className="mx-auto text-gray-400 mb-2" />
                      <p className="text-gray-500 text-sm">No applicants yet</p>
                    </div>
                  ) : (
                    <div className="space-y-3">
                      {applicants.slice(0, 3).map(applicant => {
                        const statusBadge = getApplicantStatusBadge(applicant.status);
                        return (
                          <Link
                            key={applicant.id}
                            to={`/human-capital/recruitment/applicants/${applicant.id}`}
                            className="block px-3 py-2 hover:bg-gray-100 rounded-lg"
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-700 mr-2">
                                  {applicant.name.charAt(0).toUpperCase()}
                                </div>
                                <div>
                                  <p className="text-sm font-medium text-gray-900">{applicant.name}</p>
                                  <p className="text-xs text-gray-500">Applied: {formatDate(applicant.appliedDate)}</p>
                                </div>
                              </div>
                              <span className={`px-2 py-0.5 text-xs rounded-full ${statusBadge.color}`}>
                                {statusBadge.label}
                              </span>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            /* Applicants Tab */
            <div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">Applicants ({applicants.length})</h3>
                
                <div className="flex gap-2">
                  <Link
                    to={`/human-capital/recruitment/applicants?jobId=${jobId}`}
                    className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 flex items-center"
                  >
                    <Users size={16} className="mr-2" />
                    View All Applicants
                  </Link>
                  
                  <Link
                    to="/human-capital/recruitment/applicants/new"
                    className="px-4 py-2 bg-[#190192] text-white rounded-lg hover:bg-[#2C0DB5] flex items-center"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Add Applicant
                  </Link>
                </div>
              </div>
              
              {applicants.length === 0 ? (
                <div className="bg-gray-50 rounded-xl py-12 text-center">
                  <User size={48} className="mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No applicants yet</h3>
                  <p className="text-gray-500 mb-6">
                    There are no applicants for this job posting yet.
                  </p>
                  <Link
                    to="/human-capital/recruitment/applicants/new"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
                  >
                    <PlusCircle size={16} className="mr-2" />
                    Add Applicant Manually
                  </Link>
                </div>
              ) : (
                <div className="bg-white border border-gray-200 rounded-xl overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Applicant</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Applied Date</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {applicants.map(applicant => {
                        const statusBadge = getApplicantStatusBadge(applicant.status);
                        return (
                          <tr key={applicant.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-700 flex-shrink-0">
                                  {applicant.name.charAt(0).toUpperCase()}
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">{applicant.name}</div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div>
                                <div className="text-sm text-gray-900 flex items-center">
                                  <Mail size={14} className="mr-1 text-gray-400" />
                                  <a href={`mailto:${applicant.email}`} className="hover:text-[#190192]">
                                    {applicant.email}
                                  </a>
                                </div>
                                {applicant.phone && (
                                  <div className="text-sm text-gray-500 flex items-center">
                                    <Phone size={14} className="mr-1 text-gray-400" />
                                    {applicant.phone}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {formatDate(applicant.appliedDate)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className={`px-2 py-0.5 text-xs rounded-full ${statusBadge.color}`}>
                                {statusBadge.label}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              {applicant.resumeUrl && (
                                <a 
                                  href={applicant.resumeUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-gray-500 hover:text-gray-700 px-2"
                                >
                                  <FileText size={16} />
                                </a>
                              )}
                              <Link
                                to={`/human-capital/recruitment/applicants/${applicant.id}`}
                                className="text-[#190192] hover:text-[#2C0DB5] px-2"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-medium text-gray-900 mb-4">Delete Job Posting</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete the job posting for <span className="font-medium">{job.title}</span>? This action cannot be undone.
              {applicants.length > 0 && (
                <span className="block mt-2 text-red-600">
                  Warning: This will also remove {applicants.length} applicant{applicants.length !== 1 ? 's' : ''} associated with this job.
                </span>
              )}
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setDeleteModalOpen(false)}
                className="px-4 py-2 border border-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteJob}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 font-medium"
              >
                Delete
              </button>
            </div>
          </div>
          </div>
      )}
    </>
  );
};

export default JobDetail;