// src/components/crm/support/TicketList.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Search, Filter, PlusCircle, ChevronRight, User, 
  Mail, Phone, Calendar, Tag, AlertCircle, 
  MessageSquare, Clock, MessageCircle, X
} from 'lucide-react';
import { useAuth } from '../../../context/AuthContext';
import { 
  collection, query, where, orderBy, getDocs, onSnapshot,
  limit, startAfter, getDoc, doc
} from 'firebase/firestore';
import { db } from '../../../firebase/config';

const TicketList = () => {
  const { currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  // Mock ticket data for demo/fallback
  const mockTickets = [
    {
      id: '1',
      subject: 'Cannot access account after password reset',
      customerName: 'John Smith',
      customerEmail: 'john@example.com',
      company: 'Acme Corporation',
      status: 'new',
      priority: 'high',
      category: 'Account Access',
      assignedTo: 'Sarah Kim',
      createdAt: new Date('2025-03-20T10:15:00Z'),
      updatedAt: new Date('2025-03-20T10:15:00Z'),
      messages: 2
    },
    {
      id: '2',
      subject: 'Product not working as expected',
      customerName: 'Jane Doe',
      customerEmail: 'jane@example.com',
      company: 'Beta Industries',
      status: 'in_progress',
      priority: 'medium',
      category: 'Product Issue',
      assignedTo: 'Michael Johnson',
      createdAt: new Date('2025-03-19T14:30:00Z'),
      updatedAt: new Date('2025-03-20T09:45:00Z'),
      messages: 4
    },
    {
      id: '3',
      subject: 'Need help with integration',
      customerName: 'Robert Chen',
      customerEmail: 'robert@example.com',
      company: 'Gamma Technologies',
      status: 'waiting',
      priority: 'medium',
      category: 'Technical Support',
      assignedTo: 'David Chen',
      createdAt: new Date('2025-03-18T09:20:00Z'),
      updatedAt: new Date('2025-03-20T08:15:00Z'),
      messages: 5
    },
    {
      id: '4',
      subject: 'Request for refund',
      customerName: 'Emily Wilson',
      customerEmail: 'emily@example.com',
      company: 'Delta Corp',
      status: 'waiting',
      priority: 'high',
      category: 'Billing',
      assignedTo: 'Sarah Kim',
      createdAt: new Date('2025-03-17T16:45:00Z'),
      updatedAt: new Date('2025-03-19T14:20:00Z'),
      messages: 3
    },
    {
      id: '5',
      subject: 'Feature request: Export to PDF',
      customerName: 'Michael Brown',
      customerEmail: 'michael@example.com',
      company: 'Epsilon Systems',
      status: 'in_progress',
      priority: 'low',
      category: 'Feature Request',
      assignedTo: 'David Chen',
      createdAt: new Date('2025-03-15T11:30:00Z'),
      updatedAt: new Date('2025-03-18T16:10:00Z'),
      messages: 6
    }
  ];

  // Fetch tickets from Firestore or use mock data
  useEffect(() => {
    const fetchTickets = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // Try to fetch from Firestore if user is authenticated
        if (currentUser) {
          const ticketsRef = collection(db, 'tickets');
          let q;
          
          // Build query based on filter status
          if (filterStatus !== 'all') {
            q = query(
              ticketsRef,
              where('status', '==', filterStatus),
              orderBy('updatedAt', 'desc'),
              limit(10)
            );
          } else {
            q = query(
              ticketsRef,
              orderBy('updatedAt', 'desc'),
              limit(10)
            );
          }
          
          try {
            // Set up real-time listener with error handling
            const unsubscribe = onSnapshot(q, (snapshot) => {
              if (!snapshot.empty) {
                const ticketData = [];
                snapshot.forEach((doc) => {
                  const data = doc.data();
                  ticketData.push({
                    id: doc.id,
                    ...data,
                    createdAt: data.createdAt?.toDate() || new Date(),
                    updatedAt: data.updatedAt?.toDate() || new Date(),
                    messages: data.conversation?.messages?.length || 0
                  });
                });
                
                setTickets(ticketData);
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                setHasMore(snapshot.docs.length >= 10);
                setIsLoading(false);
                return;
              }
              
              // Fall back to mock data if no records found
              console.log("No tickets found in database, using mock data");
              setTickets(mockTickets);
              setHasMore(false);
              setIsLoading(false);
            }, (err) => {
              // Handle error with listener, fall back to mock data
              console.error("Error with Firestore listener:", err);
              console.log("Falling back to mock data due to Firestore error");
              setTickets(mockTickets);
              setHasMore(false);
              setIsLoading(false);
            });
            
            // Clean up listener on unmount
            return () => unsubscribe();
          } catch (err) {
            // If onSnapshot fails entirely, use mock data
            console.error("Failed to set up Firestore listener:", err);
            setTickets(mockTickets);
            setHasMore(false);
            setIsLoading(false);
          }
        } else {
          // No authenticated user, use mock data
          console.log("No authenticated user, using mock data");
          setTickets(mockTickets);
          setHasMore(false);
          setIsLoading(false);
        }
      } catch (err) {
        // Catch any other errors and use mock data
        console.error("General error fetching tickets:", err);
        setTickets(mockTickets);
        setHasMore(false);
        setIsLoading(false);
      }
    };
    
    fetchTickets();
  }, [currentUser, filterStatus]);

  // Filter tickets by status if needed
  useEffect(() => {
    if (filterStatus !== 'all' && !currentUser) {
      // When using mock data and filtering is applied
      const filtered = mockTickets.filter(ticket => ticket.status === filterStatus);
      setTickets(filtered);
    }
  }, [filterStatus, currentUser]);

  // Load more tickets (only works with Firestore)
  const loadMoreTickets = async () => {
    if (!lastVisible || !hasMore || !currentUser) return;
    
    try {
      const ticketsRef = collection(db, 'tickets');
      let q;
      
      if (filterStatus !== 'all') {
        q = query(
          ticketsRef,
          where('status', '==', filterStatus),
          orderBy('updatedAt', 'desc'),
          startAfter(lastVisible),
          limit(10)
        );
      } else {
        q = query(
          ticketsRef,
          orderBy('updatedAt', 'desc'),
          startAfter(lastVisible),
          limit(10)
        );
      }
      
      const snapshot = await getDocs(q);
      
      if (snapshot.empty) {
        setHasMore(false);
      } else {
        const newTickets = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          newTickets.push({
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate() || new Date(),
            updatedAt: data.updatedAt?.toDate() || new Date(),
            messages: data.conversation?.messages?.length || 0
          });
        });
        
        setTickets(prev => [...prev, ...newTickets]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setHasMore(snapshot.docs.length >= 10);
      }
    } catch (err) {
      console.error("Error loading more tickets:", err);
      setError("Failed to load more tickets");
    }
  };

  // Get filtered tickets based on search term
  const getFilteredTickets = () => {
    if (!searchTerm) return tickets;
    
    return tickets.filter(ticket => 
      ticket.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ticket.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (ticket.company && ticket.company.toLowerCase().includes(searchTerm.toLowerCase())) ||
      ticket.customerEmail.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Format date
  const formatDate = (date) => {
    if (!date) return '';
    
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Status and priority display info functions...
  const getStatusInfo = (status) => {
    switch (status) {
      case 'new':
        return { label: 'New', color: 'bg-blue-100 text-blue-800' };
      case 'in_progress':
        return { label: 'In Progress', color: 'bg-yellow-100 text-yellow-800' };
      case 'waiting':
        return { label: 'Waiting', color: 'bg-purple-100 text-purple-800' };
      case 'resolved':
        return { label: 'Resolved', color: 'bg-green-100 text-green-800' };
      case 'closed':
        return { label: 'Closed', color: 'bg-gray-100 text-gray-800' };
      default:
        return { label: status, color: 'bg-gray-100 text-gray-800' };
    }
  };

  const getPriorityInfo = (priority) => {
    switch (priority) {
      case 'high':
        return { label: 'High', color: 'text-red-600' };
      case 'medium':
        return { label: 'Medium', color: 'text-orange-600' };
      case 'low':
        return { label: 'Low', color: 'text-green-600' };
      default:
        return { label: priority, color: 'text-gray-600' };
    }
  };

  return (
    <div>
      {/* Search and Actions Bar */}
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <input
            type="text"
            className="pl-10 pr-4 py-2.5 w-full rounded-lg text-sm border border-gray-200 focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            placeholder="Search tickets by subject, customer, or company..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        
        <div className="flex gap-3">
          <select 
            className="px-4 py-2.5 rounded-lg border border-gray-200 text-gray-600 bg-white focus:border-[#190192] focus:ring-2 focus:ring-[#190192] focus:ring-opacity-20"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="new">New</option>
            <option value="in_progress">In Progress</option>
            <option value="waiting">Waiting</option>
            <option value="resolved">Resolved</option>
            <option value="closed">Closed</option>
          </select>
          
          <Link
            to="/crm/support/new"
            className="px-4 py-2.5 rounded-lg bg-[#190192] text-white flex items-center hover:bg-[#2C0DB5]"
          >
            <PlusCircle size={18} className="mr-2" />
            Create Ticket
          </Link>
        </div>
      </div>

      {/* Error message */}
      {error && (
        <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
          <p className="flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </p>
        </div>
      )}

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin h-8 w-8 border-4 border-[#190192] rounded-full border-t-transparent"></div>
        </div>
      ) : (
        <>
          {/* Empty State */}
          {tickets.length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <MessageCircle size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No support tickets found</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                You haven't created any support tickets yet. Click the 'Create Ticket' button to get started.
              </p>
              <Link
                to="/crm/support/new"
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#190192] hover:bg-[#2C0DB5]"
              >
                <PlusCircle size={16} className="mr-2" />
                Create Your First Ticket
              </Link>
            </div>
          ) : getFilteredTickets().length === 0 ? (
            <div className="bg-gray-50 rounded-lg py-10 px-6 text-center">
              <MessageCircle size={48} className="mx-auto text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No matching tickets</h3>
              <p className="text-gray-500 mb-6 max-w-md mx-auto">
                We couldn't find any tickets matching your search criteria. Try adjusting your search or filters.
              </p>
              <button
                onClick={() => { setSearchTerm(''); setFilterStatus('all'); }}
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Clear Filters
              </button>
            </div>
          ) : (
            /* Tickets List/Table */
            <div className="bg-white rounded-xl shadow-md overflow-hidden">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ticket
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Customer
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Assigned To
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Last Updated
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {getFilteredTickets().map((ticket) => {
                      const statusInfo = getStatusInfo(ticket.status);
                      const priorityInfo = getPriorityInfo(ticket.priority);
                      
                      return (
                        <tr key={ticket.id} className="hover:bg-gray-50">
                          <td className="px-6 py-4">
                            <div className="flex flex-col">
                              <div className="flex items-start">
                                <div className={`flex-shrink-0 h-5 w-5 rounded-full ${
                                  ticket.priority === 'high' ? 'bg-red-500' :
                                  ticket.priority === 'medium' ? 'bg-orange-500' : 'bg-green-500'
                                } mr-2 mt-0.5`} />
                                <div>
                                  <div className="text-sm font-medium text-gray-900">{ticket.subject}</div>
                                  <div className="text-xs text-gray-500 mt-1 flex items-center">
                                    <MessageSquare size={12} className="mr-1" /> {ticket.messages} messages
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex flex-col">
                              <div className="text-sm font-medium text-gray-900">{ticket.customerName}</div>
                              <div className="text-xs text-gray-500">{ticket.company}</div>
                              <div className="text-xs text-gray-500 flex items-center mt-1">
                                <Mail size={12} className="mr-1" /> {ticket.customerEmail}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusInfo.color}`}>
                              {statusInfo.label}
                            </span>
                            <div className="text-xs mt-1 font-medium flex items-center">
                              <AlertCircle size={12} className={`mr-1 ${priorityInfo.color}`} />
                              <span className={priorityInfo.color}>{priorityInfo.label} Priority</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                              {ticket.category}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {ticket.assignedTo}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center">
                            <Clock size={14} className="mr-1 text-gray-400" /> {formatDate(ticket.updatedAt)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <Link
                              to={`/crm/support/${ticket.id}`}
                              className="text-[#190192] hover:text-[#2C0DB5] inline-flex items-center"
                            >
                              View <ChevronRight size={16} className="ml-1" />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              
              {/* Load More button */}
              {hasMore && (
                <div className="px-6 py-4 border-t border-gray-200 text-center">
                  <button
                    onClick={loadMoreTickets}
                    className="px-4 py-2 text-sm text-[#190192] font-medium hover:bg-purple-50 rounded-lg"
                  >
                    Load More Tickets
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TicketList;